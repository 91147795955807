import React from "react";
import { RouteObject } from "react-router";

import { userSettingsRoute } from "../../../routes/userSettings";
import lazyWithRetry from "../../../services/lazyWithRetry";
import DemandLayout from "../components/layouts/DemandLayout";
import { businessMapRoute } from "./businessMap";

const Dashboard = lazyWithRetry(() => import("../pages/Dashboard/Dashboard"));

const DEMAND_PATH = "demand";

export const demandRoute: RouteObject = {
  path: DEMAND_PATH,
  element: <DemandLayout />,
  children: [
    { index: true, element: <Dashboard /> },
    businessMapRoute,
    userSettingsRoute,
  ],
};
