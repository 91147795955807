import React from "react";
import { RouteObject } from "react-router";

import { tasksRoute } from "../../../routes/tasks";
import { userSettingsRoute } from "../../../routes/userSettings";
import lazyWithRetry from "../../../services/lazyWithRetry";
import PrognosLayout from "../components/layouts/PrognosLayout";
import { helpRoute } from "./help";
import { influencingFactorsRoute } from "./influencingFactors";
import { modelsRoute } from "./models";
import { outliersRoute } from "./outliers";
import { PROGNOS_PATH } from "./prognos";
import { resultsRoute } from "./results";
import { runConfigsRoute } from "./runConfigs";

const Dashboard = lazyWithRetry(() => import("../pages/Dashboard/Dashboard"));

export const prognosRoute: RouteObject = {
  path: PROGNOS_PATH,
  element: <PrognosLayout />,
  children: [
    { index: true, element: <Dashboard /> },
    outliersRoute,
    influencingFactorsRoute,
    modelsRoute,
    runConfigsRoute,
    resultsRoute,
    userSettingsRoute,
    helpRoute,
    tasksRoute,
  ],
};
