import React from "react";
import { useTranslation } from "react-i18next";

import ListRadio from "../../../components/form/ListRadio";
import Select from "../../../components/form/Select";
import NarrowFormItem from "../../PrognosAI/components/narrowform/NarrowFormItem";
import { tenum } from "../../PrognosAI/services/translationMappings";
import { ConvertValuesOption, convertValuesOptions } from "../api/analysis";

interface NarrowConvertValuesSelectProps {
  id: string;
  isContained: boolean;
  value: ConvertValuesOption;
  onChange(value: ConvertValuesOption): void;
  isDefault?: boolean;
}

export default function NarrowConvertValuesSelect(
  props: NarrowConvertValuesSelectProps
): JSX.Element {
  const { id, isContained, value, onChange, isDefault } = props;

  const { t } = useTranslation();

  const convertOptions = convertValuesOptions.map((key) => ({
    key,
    value: key,
    label: tenum(`ConvertValues:${key}`, t),
  }));
  const usedConvertOption = convertOptions.find(
    (option) => option.key === value
  );

  return (
    <NarrowFormItem
      label={t("Convert values")}
      htmlFor={id}
      isDefault={isDefault}
      forceNarrow={isContained}
      standalone={isContained}
    >
      {!!isContained && (
        <Select
          id={id}
          options={convertOptions}
          value={usedConvertOption ?? null}
          onChange={({ key }) => onChange(key)}
        />
      )}
      {!isContained && (
        <div className="my-2">
          <ListRadio
            id={id}
            options={convertOptions}
            value={usedConvertOption ?? null}
            onChange={({ key }) => onChange(key)}
          />
        </div>
      )}
    </NarrowFormItem>
  );
}
