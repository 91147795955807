import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { z } from "zod";

import api, { getQueryString } from "../../../api";
import { EndpointOptions } from "../../../api";
import {
  getImportDetailPath,
  getImportPath,
} from "../../DataStore/routes/import";
import {
  DataImportConfig,
  DataImportConfigDetail,
  DataImportConfigDetailZod,
  DataImportConfigZod,
} from "../models/dataImportConfig";
import {
  DataImportConfigDependency,
  DataImportConfigErrorZod,
} from "../models/dependency";
import { PaginatedResponse, paginatedResponse } from "../models/response";
import {
  CompleteUpload,
  UploadLinkContainer,
  UploadLinkContainerZod,
} from "../models/upload";
import { activeDataImportConfigTasksQuery } from "./tasks";

const DATA_IMPORT_API = "/Datastore/DataImportConfigs";

async function getDataImportConfigs(
  solutionId: string,
  options: EndpointOptions = {}
): Promise<PaginatedResponse<DataImportConfig[]>> {
  const query = getQueryString(options);
  const dataImportConfigsQ = paginatedResponse(
    DataImportConfigZod.array()
  ).parse(
    (
      await api.get(
        `/Datastore/Solutions/${solutionId}/DataImportConfigs?${query}`
      )
    ).data
  );
  return dataImportConfigsQ;
}

export const dataImportConfigsQuery = (
  solutionId: string,
  options?: EndpointOptions
) => ({
  queryKey: ["dataImportConfigs", solutionId, ...(options ? [options] : [])],
  queryFn: () => getDataImportConfigs(solutionId, options),
});

async function getDataImportConfig(
  dataImportConfigId: string | number
): Promise<DataImportConfigDetail> {
  return DataImportConfigDetailZod.parse(
    (await api.get(`${DATA_IMPORT_API}/${dataImportConfigId}`)).data
  );
}

export const dataImportConfigQuery = (dataImportConfigId: string | number) => ({
  queryKey: ["dataImportConfig", dataImportConfigId.toString()],
  queryFn: async () => await getDataImportConfig(dataImportConfigId),
});

async function createDataImportConfig(
  solutionId: string,
  dataImportConfig: Omit<DataImportConfigDetail, "dataImportConfigId">
): Promise<DataImportConfigDetail> {
  return DataImportConfigDetailZod.parse(
    (
      await api.post(
        `/Datastore/Solutions/${solutionId}/DataImportConfigs`,
        dataImportConfig
      )
    ).data
  );
}

export const useCreateDataImportConfig = (
  solutionId: string,
  noRedirect = false,
  silent = false
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (
      dataImportConfig: Omit<DataImportConfigDetail, "dataImportConfigId">
    ) => createDataImportConfig(solutionId, dataImportConfig),
    onSuccess: ({ dataImportConfigId: dataImportConfigId }) => {
      if (!silent) {
        toast.success(t("Import configuration saved successfully."));
      }
      queryClient.invalidateQueries(dataImportConfigsQuery(solutionId));
      if (!noRedirect) {
        navigate(getImportDetailPath(solutionId, dataImportConfigId));
      }
    },
    onError: () => {
      if (!silent) {
        toast.error(t("An error occurred while saving. Please try again."));
      }
    },
  });
};

async function updateDataImportConfig(
  dataImportConfigId: string,
  patch: Partial<DataImportConfigDetail>
) {
  return DataImportConfigDetailZod.parse(
    (await api.patch(`${DATA_IMPORT_API}/${dataImportConfigId}`, patch)).data
  );
}

export const useEditDataImportConfig = (
  solutionId: string,
  dataImportConfigId: string
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (patch: Partial<DataImportConfigDetail>) =>
      updateDataImportConfig(dataImportConfigId, patch),
    onSuccess: (newDataImportConfig) => {
      queryClient.setQueryData(
        ["dataImportConfig", dataImportConfigId],
        newDataImportConfig
      );
      queryClient.invalidateQueries(dataImportConfigsQuery(solutionId));
    },
    onError: () => {
      toast.error(
        t(
          "The changes were not saved. Please wait or refresh the page to start over."
        )
      );
    },
  });
};

async function deleteDataImportConfig(dataImportConfigId: number | string) {
  return api.delete(`${DATA_IMPORT_API}/${dataImportConfigId}`);
}

export const useDeleteDataImportConfig = (
  solutionId: string,
  redirect = false,
  silent = false
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (dataImportConfigId: number | string) =>
      deleteDataImportConfig(dataImportConfigId),
    onSuccess: (_, dataImportConfigId) => {
      if (!silent) {
        toast.success(t("Import configuration deleted successfully."));
      }
      queryClient.invalidateQueries(dataImportConfigsQuery(solutionId));
      queryClient.removeQueries(dataImportConfigQuery(dataImportConfigId));
      if (redirect) {
        navigate(getImportPath(solutionId));
      }
    },
    onError: () => {
      toast.error(t("An error occurred while deleting. Please try again."));
    },
  });
};

async function deleteDataImportConfigsByIds(ids: number[]) {
  return api.delete(`${DATA_IMPORT_API}/Batch`, { data: { ids } });
}

export const useDeleteDataImportConfigsByIds = (solutionId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: deleteDataImportConfigsByIds,
    onSuccess: (_, ids) => {
      toast.success(t("Import configurations deleted successfully."));
      queryClient.invalidateQueries(dataImportConfigsQuery(solutionId));
      queryClient.removeQueries(...ids.map((id) => dataImportConfigQuery(id)));
    },
    onError: () => {
      toast.error(t("An error occurred while deleting. Please try again."));
    },
  });
};

async function deleteDataImportConfigsByFilter(
  solutionId: string | number,
  options: EndpointOptions
) {
  const query = getQueryString(options);
  return api.delete(
    `/Datastore/Solutions/${solutionId}/DataImportConfigs?${query}`
  );
}

export const useDeleteDataImportConfigsByFilter = (solutionId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (options: EndpointOptions) =>
      deleteDataImportConfigsByFilter(solutionId, options),
    onSuccess: () => {
      toast.success(t("Import configurations deleted successfully."));
      queryClient.invalidateQueries(dataImportConfigsQuery(solutionId));
    },
    onError: () => {
      toast.error(t("An error occurred while deleting. Please try again."));
    },
  });
};

export async function getDataImportConfigUploadContainer(
  dataImportConfigId: number | string,
  fileName: string,
  fileSize: number
): Promise<UploadLinkContainer> {
  return UploadLinkContainerZod.parse(
    (
      await api.get(
        `${DATA_IMPORT_API}/${dataImportConfigId}/UploadLink?fileName=${fileName}&fileSize=${fileSize}`
      )
    ).data
  );
}

export async function completeDataImportConfigUpload(
  dataImportConfigId: number | string,
  data: CompleteUpload
) {
  return api.post(
    `${DATA_IMPORT_API}/${dataImportConfigId}/CompleteUpload`,
    data
  );
}

export async function getDataImportConfigDownloadLink(
  dataImportConfigId: string | number
): Promise<string> {
  const response = z
    .object({ link: z.string() })
    .parse(
      (await api.get(`${DATA_IMPORT_API}/${dataImportConfigId}/DownloadLink`))
        .data
    );
  return response.link;
}

export const dataImportConfigDownloadLinkQuery = (
  dataImportConfigId: string
) => ({
  queryKey: ["dataImportConfigDownloadLink", dataImportConfigId],
  queryFn: async () =>
    await getDataImportConfigDownloadLink(dataImportConfigId),
});

async function startDataImportConfigAnalysis(
  dataImportConfigId: string | number
) {
  return api.post(`${DATA_IMPORT_API}/${dataImportConfigId}/Analyze`);
}

export const useStartDataImportConfigAnalysis = (
  solutionId: string | number
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (dataImportConfigId: string | number) =>
      startDataImportConfigAnalysis(dataImportConfigId),
    onSuccess: (_, dataImportConfigId) => {
      queryClient.invalidateQueries(dataImportConfigQuery(dataImportConfigId));
      queryClient.invalidateQueries(
        activeDataImportConfigTasksQuery(
          `${solutionId}`,
          `${dataImportConfigId}`
        )
      );
    },
    onError: () => {
      toast.error(t("An error has occurred. Please try again."));
    },
  });
};

async function startDataImportConfigImport(
  dataImportConfigId: number | string,
  force = false
) {
  return api.post(
    `${DATA_IMPORT_API}/${dataImportConfigId}/Import${force ? "?force_on_replace=True" : ""}`
  );
}

export const useStartDataImportConfigImport = (
  solutionId: string | number,
  dataImportConfigId: string | number,
  onConfirmation: (deps: DataImportConfigDependency[]) => void
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: ({ force = false }: { force?: boolean } = {}) =>
      startDataImportConfigImport(dataImportConfigId, force),
    onSuccess: () => {
      queryClient.invalidateQueries(dataImportConfigQuery(dataImportConfigId));
      queryClient.invalidateQueries(
        activeDataImportConfigTasksQuery(
          `${solutionId}`,
          `${dataImportConfigId}`
        )
      );
    },
    onError: (error) => {
      if (error instanceof AxiosError && error.response?.status === 422) {
        try {
          console.log(error.response.data);
          const response = DataImportConfigErrorZod.parse(error.response.data);
          return onConfirmation(response.values);
        } catch (error) {
          console.error(error);
        }
      }
      toast.error(t("An error has occurred. Please try again."));
    },
  });
};
