import React from "react";
import { RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getDemandPath } from "./demand";
import { resultsRoute } from "./results";
import { settingsRoute } from "./settings";
import { variablesRoute } from "./variables";

const BusinessMap = lazyWithRetry(
  () => import("../pages/BusinessMap/BusinessMap")
);

export const businessMapRoute: RouteObject = {
  path: ":businessMapId",
  children: [
    { index: true, element: <BusinessMap /> },
    settingsRoute,
    variablesRoute,
    resultsRoute,
  ],
};

export function getBusinessMapPath(
  solutionId: string | number,
  businessMapId: string | number
): string {
  return `${getDemandPath(solutionId)}/${businessMapId}`;
}
