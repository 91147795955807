import React from "react";
import { RouteObject } from "react-router";

import lazyWithRetry from "../services/lazyWithRetry";
import { getSolutionPath } from "./solutions";

const SolutionSettings = lazyWithRetry(
  () => import("../pages/SolutionSettings/SolutionSettings")
);

const SOLUTION_SETTINGS_PATH = "settings";

export const solutionSettingsRoute: RouteObject = {
  path: SOLUTION_SETTINGS_PATH,
  element: <SolutionSettings />,
};

export function getSolutionSettingsPath(solutionId: string | number): string {
  return `${getSolutionPath(solutionId)}/${SOLUTION_SETTINGS_PATH}`;
}
