import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router";

import { RectangleStackIcon, UserIcon } from "@heroicons/react/24/outline";

import { useSolution } from "../../api/solutions";
import { getCharIcon } from "../../apps/DataStore/pages/DataImportConfig/components/DatasetMenu";
import BasicLayout from "../../apps/PrognosAI/components/layouts/BasicLayout";
import ErrorPage from "../../pages/ErrorPage/ErrorPage";
import SettingsMenu from "../../pages/UserSettings/components/SettingsMenu";
import { getSolutionSettingsPath } from "../../routes/solutionSettings";
import { getSolutionPath } from "../../routes/solutions";
import { getTasksPath } from "../../routes/tasks";
import { getUserSettingsPath } from "../../routes/userSettings";
import { useSuiteApps } from "../Apps";
import Sidebar, { SidebarItem } from "../sidebar/Sidebar";
import BasicSkeleton from "../skeletons/BasicSkeleton";
import ListPageSkeleton from "../skeletons/ListPageSkeleton";
import SettingsSkeleton from "../skeletons/SettingsSkeleton";

export default function SolutionLayout(): JSX.Element {
  const [{ data: solution, ...solutionRes }, solutionId] = useSolution();
  const [projectTop, projectBottom] = useGeneralMenuItems(solutionId);
  const [solutionTop] = useSolutionMenuItems();

  const topNavigation = [projectTop, solutionTop];

  const skeleton = useSkeleton();

  if (solutionRes.isError) {
    return <ErrorPage error={solutionRes.error} />;
  }

  return (
    <Sidebar
      topNavigation={topNavigation}
      bottomNavigation={projectBottom}
      solution={solution}
    >
      <BasicLayout skeleton={skeleton} />
    </Sidebar>
  );
}

function useSkeleton(): JSX.Element {
  const { pathname } = useLocation();
  const params = useParams();
  const { solutionId } = params;

  if (!solutionId) {
    return <BasicSkeleton />;
  }

  // user settings
  if (pathname.startsWith(getUserSettingsPath(solutionId, undefined))) {
    return <SettingsSkeleton menu={<SettingsMenu />} />;
  }

  // project settings
  if (pathname.startsWith(getSolutionSettingsPath(solutionId))) {
    return <SettingsSkeleton />;
  }

  // runtime information
  if (pathname.startsWith(getTasksPath(solutionId, undefined))) {
    return <ListPageSkeleton />;
  }

  if (pathname.startsWith(getSolutionPath(solutionId))) {
    // TODO better skeleton when the dashboard is finished
    return <BasicSkeleton />;
  }

  return <BasicSkeleton />;
}

function useGeneralMenuItems(
  solutionId: string
): [SidebarItem[], SidebarItem[]] {
  const location = useLocation();
  const { t } = useTranslation();

  const topNavigation: SidebarItem[] = [];

  const bottomNavigation: SidebarItem[] = [
    {
      key: "userSettings",
      name: t("User settings"),
      href: getUserSettingsPath(solutionId, undefined),
      icon: UserIcon,
      isCurrent: location.pathname.startsWith(
        getUserSettingsPath(solutionId, undefined)
      ),
    },
    // {
    //   key: "help",
    //   name: t("Help"),
    //   href: getHelpPath(solutionId, "PrognosAI"),
    //   icon: QuestionMarkCircleIcon,
    //   current: location.pathname.startsWith(
    //     getHelpPath(solutionId, "PrognosAI")
    //   ),
    // },
    {
      key: "tasks",
      name: t("System tasks"),
      href: getTasksPath(solutionId, undefined),
      icon: RectangleStackIcon,
      isCurrent: location.pathname.startsWith(
        getTasksPath(solutionId, undefined)
      ),
    },
  ];

  return [topNavigation, bottomNavigation];
}

function useSolutionMenuItems(): [SidebarItem[], SidebarItem[]] {
  const [, solutionId] = useSolution();
  const apps = useSuiteApps();

  const topItems: SidebarItem[] = apps
    .filter((app) => app.link !== getUserSettingsPath(solutionId, undefined))
    .map((app) => ({
      key: app.link,
      name: app.label,
      href: app.link,
      icon: app.icon ?? getCharIcon(app.label[0]),
      isCurrent: location.pathname === app.link,
    }));

  return [topItems, []];
}
