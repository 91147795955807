import React from "react";
import { RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getBusinessMapPath } from "./businessMap";

const Results = lazyWithRetry(() => import("../pages/Results/Results"));

const RESULTS_PATH = "results";

export const resultsRoute: RouteObject = {
  path: RESULTS_PATH,
  element: <Results />,
};

export function getResultsPath(
  solutionId: string | number,
  businessMapId: string | number
): string {
  return `${getBusinessMapPath(solutionId, businessMapId)}/${RESULTS_PATH}`;
}
