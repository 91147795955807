import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import {
  QuestionMarkCircleIcon,
  RectangleStackIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

import { SidebarItem } from "../../../components/sidebar/Sidebar";
import { getTasksPath } from "../../../routes/tasks";
import { getUserSettingsPath } from "../../../routes/userSettings";
import { getHelpPath } from "../routes/help";

export default function useGeneralMenuItems(
  solutionId?: string
): [SidebarItem[], SidebarItem[]] {
  const location = useLocation();
  const { t } = useTranslation();

  const topNavigation: SidebarItem[] = [];

  const bottomNavigation: SidebarItem[] = [
    {
      key: "userSettings",
      name: t("User settings"),
      href: getUserSettingsPath(solutionId, "PrognosAI"),
      icon: UserIcon,
      isCurrent: location.pathname.startsWith(
        getUserSettingsPath(solutionId, "PrognosAI")
      ),
    },
    {
      key: "help",
      name: t("Help"),
      href: getHelpPath(solutionId, "PrognosAI"),
      icon: QuestionMarkCircleIcon,
      isCurrent: location.pathname.startsWith(
        getHelpPath(solutionId, "PrognosAI")
      ),
    },
    {
      key: "tasks",
      name: t("System tasks"),
      href: getTasksPath(solutionId, "PrognosAI"),
      icon: RectangleStackIcon,
      isCurrent: location.pathname.startsWith(
        getTasksPath(solutionId, "PrognosAI")
      ),
    },
  ];

  return [topNavigation, bottomNavigation];
}
