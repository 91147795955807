import React from "react";
import { useTranslation } from "react-i18next";

import TextField from "../../../components/form/TextField";
import NarrowFormItem from "../../PrognosAI/components/narrowform/NarrowFormItem";

interface NarrowValueRangeFieldsProps {
  id: string;
  minValue: number | null;
  maxValue: number | null;
  onChange(
    patch: Partial<{ minValue: number | null; maxValue: number | null }>
  ): void;
  isContained: boolean;
  unit?: string | null;
}

export default function NarrowValueRangeFields(
  props: NarrowValueRangeFieldsProps
): JSX.Element {
  const { id, minValue, maxValue, onChange, isContained, unit } = props;

  const { t } = useTranslation();

  const showValPrecedeError =
    minValue !== null && maxValue !== null && minValue > maxValue;

  return (
    <NarrowFormItem
      label={
        <>
          {t("Values range")}{" "}
          {unit && <span className="text-gray-400 font-normal">[{unit}]</span>}
        </>
      }
      htmlFor={`${id}-minValue`}
      isDefault={!isContained && minValue === null && maxValue === null}
      forceNarrow={isContained}
      standalone={isContained}
    >
      <div className="flex items-center space-x-2 text-sm">
        <TextField
          id={`${id}-minValue`}
          type="number"
          value={minValue ?? ""}
          placeholder={t("Min value")}
          error={!!showValPrecedeError}
          onChange={(e) => {
            const val = parseFloat(e.target.value);
            onChange({ minValue: !isNaN(val) ? val : null, maxValue });
          }}
        />
        <span>{t("to")}</span>
        <TextField
          id={`${id}-maxValue`}
          type="number"
          value={maxValue ?? ""}
          placeholder={t("Max value")}
          error={!!showValPrecedeError}
          onChange={(e) => {
            const val = parseFloat(e.target.value);
            onChange({ minValue, maxValue: !isNaN(val) ? val : null });
          }}
        />
      </div>
      {showValPrecedeError && (
        <p className="text-sm text-red-600">
          {t("The minimal value must precede the maximal value.")}
        </p>
      )}
      {isContained && <div className="h-2" />}
    </NarrowFormItem>
  );
}
