import useAggregatesCalcMessageCallback from "../apps/DataStore/pages/Import/hooks/useAggregatesCalcMessageCallback";
import { useDatasetMessagesCallback } from "../apps/DataStore/pages/Import/hooks/useDatasetMessagesCallback";
import useDirtyAggregatesMessageCallback from "../apps/DataStore/pages/Import/hooks/useDirtyAggregatesMessageCallback";
import useExportMessageCallback from "../apps/PrognosAI/hooks/useExportMessageCallback";
import useImportMessageCallback from "../apps/PrognosAI/hooks/useImportMessageCallback";
import useMessageHook from "../apps/PrognosAI/hooks/useMessageHook";
import useOutlierDetectionMessageCallback from "../apps/PrognosAI/hooks/useOutlierDetectionMessageCallback";
import { useRunMessagesCallback } from "../apps/PrognosAI/pages/Run/hooks/useRunMessagesCallback";

/**
 * Catches all the solution-related messages using specific hooks that react accordingly.
 *
 * Place to register all solution-related message hooks.
 */
export default function useSolutionMessages() {
  // model runs
  const runTasksMessagesCallback = useRunMessagesCallback();
  useMessageHook(runTasksMessagesCallback);

  // imports
  const datasetMessagesCallback = useDatasetMessagesCallback();
  useMessageHook(datasetMessagesCallback);

  // prompt to recalculate aggregates
  const dirtyAggregatesCallback = useDirtyAggregatesMessageCallback();
  useMessageHook(dirtyAggregatesCallback);

  // aggregates recalculation progress
  const aggregatesCalcMessagesCallback = useAggregatesCalcMessageCallback();
  useMessageHook(aggregatesCalcMessagesCallback);

  const outlierDetectionMessageCallback = useOutlierDetectionMessageCallback();
  useMessageHook(outlierDetectionMessageCallback);

  const exportMessageCallback = useExportMessageCallback();
  useMessageHook(exportMessageCallback);

  const importMessageCallback = useImportMessageCallback();
  useMessageHook(importMessageCallback);
}
