import React from "react";
import { Outlet, useParams } from "react-router";

import classNames from "classnames";

import BasicSkeleton from "../../../../components/skeletons/BasicSkeleton";
import SkeletonWrapper from "../../../../components/skeletons/SkeletonWrapper";

interface BasicLayoutProps {
  skeleton?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
}

export default function BasicLayout(props: BasicLayoutProps): JSX.Element {
  const { children, className } = props;

  const defaultSkeleton = useSkeleton();
  const { skeleton = defaultSkeleton } = props;

  return (
    <main className={classNames("grow p-4 flex flex-col", className)}>
      <React.Suspense fallback={skeleton}>
        <Outlet />
        {children}
      </React.Suspense>
    </main>
  );
}

function useSkeleton(): JSX.Element {
  const { solutionId } = useParams();

  if (solutionId) {
    return <BasicSkeleton />;
  }

  return (
    <SkeletonWrapper className="w-full grow">
      <div></div>
    </SkeletonWrapper>
  );
}
