import React from "react";
import { useTranslation } from "react-i18next";

import Alert from "../../../components/Alert";
import Slideover from "../../../components/Slideover";
import NarrowFormItem from "../../PrognosAI/components/narrowform/NarrowFormItem";
import { getLeafPartitions } from "../../PrognosAI/functions/partition";
import { SolutionDetail } from "../../PrognosAI/models/solution";
import SelectablePartitionTree from "../../PrognosAI/pages/Run/components/SelectablePartitionTree";

interface NarrowPartitionsSelectProps {
  id: string;
  solution: SolutionDetail;
  isContained: boolean;
  partitionIds: number[];
  onChange(partitionIds: number[]): void;
}

export default function NarrowPartitionsSelect(
  props: NarrowPartitionsSelectProps
): JSX.Element {
  const { id, solution, isContained, partitionIds, onChange } = props;

  const { t } = useTranslation();

  const showContainedPartitions =
    isContained && solution.partitions.length > LIST_THRESHOLD + 1;

  const pickerId = `${id}-picker`;

  return (
    <NarrowFormItem
      id={id}
      label={t("Source planning areas")}
      htmlFor={pickerId}
      forceNarrow={isContained}
      standalone={isContained}
    >
      {!!showContainedPartitions && (
        <ContainedPartitions
          solution={solution}
          partitionIds={partitionIds}
          onChange={onChange}
        />
      )}
      {!showContainedPartitions && (
        <SelectablePartitionTree
          id={pickerId}
          partitioners={solution.partitioners}
          partitions={solution.partitions}
          usedPartitions={partitionIds}
          defaultOpen={isTreeDefaultOpen(solution)}
          forcedView="leaves"
          onChange={onChange}
        />
      )}
    </NarrowFormItem>
  );
}

function isTreeDefaultOpen(solution: SolutionDetail): boolean {
  const sortedPartitioners = [...solution.partitioners];
  sortedPartitioners.sort((a, b) => a.order - b.order);

  const firstPartitioner = sortedPartitioners.at(0);
  if (!firstPartitioner) {
    return true;
  }

  const highestAreas = solution.partitions.filter(
    (p) => p.partitionerId === firstPartitioner.partitionerId
  );

  return highestAreas.length <= 15;
}

type ContainedPartitionsProps = {
  solution: SolutionDetail;
  partitionIds: number[];
  onChange(partitionIds: number[]): void;
};

function ContainedPartitions(props: ContainedPartitionsProps): JSX.Element {
  const { solution, partitionIds, onChange } = props;

  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <div className="text-sm flex items-center justify-between space-x-2">
        <PartitionsList {...props} />
        <p className="text-right my-2">
          <button
            className="underline text-blue-600"
            onClick={() => setOpen(true)}
          >
            {t("Change")}
          </button>
        </p>
      </div>
      <Slideover
        title={t("Select planning areas")}
        open={open}
        onClose={() => setOpen(false)}
      >
        <SelectablePartitionTree
          id="runPartitionsPicker"
          partitioners={solution.partitioners}
          partitions={solution.partitions}
          usedPartitions={partitionIds}
          defaultOpen
          forcedView="leaves"
          onChange={onChange}
        />
      </Slideover>
    </>
  );
}

const LIST_THRESHOLD = 5;

type PartitionsListProps = {
  solution: SolutionDetail;
  partitionIds: number[];
};

function PartitionsList(props: PartitionsListProps): JSX.Element {
  const { solution, partitionIds } = props;

  const { t } = useTranslation();

  if (partitionIds.length === 0) {
    return <Alert type="warning">{t("No planning area is selected.")}</Alert>;
  }

  const allLeaves = getLeafPartitions(
    solution.partitions,
    solution.partitioners
  );
  const unselectedLeaves = allLeaves.filter(
    (leaf) => !partitionIds.includes(leaf.partitionId)
  );

  if (unselectedLeaves.length === 0) {
    return (
      <Alert type="info">
        {t("All planning areas are selected.", {
          count: partitionIds.length,
        })}
      </Alert>
    );
  }

  const selectedLeaves = allLeaves.filter((leaf) =>
    partitionIds.includes(leaf.partitionId)
  );
  if (selectedLeaves.length < LIST_THRESHOLD) {
    selectedLeaves.sort((a, b) => a.fullName.localeCompare(b.fullName));
    return (
      <div className="grow truncate">
        <Alert type="info">{t("Selected planning areas:")}</Alert>
        <ul className="w-full list-inside pl-8 list-disc">
          {selectedLeaves.map((leaf) => (
            <li key={leaf.partitionId} className="w-full pl-1 my-1 truncate">
              {leaf.fullName}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  if (unselectedLeaves.length < LIST_THRESHOLD) {
    unselectedLeaves.sort((a, b) => a.fullName.localeCompare(b.fullName));
    return (
      <div className="grow truncate">
        <Alert type="warning">{t("Omitted planning areas:")}</Alert>
        <ul className="w-full list-inside pl-8 list-disc">
          {unselectedLeaves.map((leaf) => (
            <li key={leaf.partitionId} className="w-full pl-1 my-1 truncate">
              {leaf.fullName}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <Alert type="info">
      {t("Total {{count}} planning areas are selected.", {
        count: partitionIds.length,
      })}
    </Alert>
  );
}
