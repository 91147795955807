import React from "react";
import { RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getBusinessMapPath } from "./businessMap";

const Settings = lazyWithRetry(() => import("../pages/Settings/Settings"));

const SETTINGS_PATH = "settings";

export const settingsRoute: RouteObject = {
  path: SETTINGS_PATH,
  element: <Settings />,
};

export function getSettingsPath(
  solutionId: string | number,
  businessMapId: string | number
): string {
  return `${getBusinessMapPath(solutionId, businessMapId)}/${SETTINGS_PATH}`;
}
