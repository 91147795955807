import React from "react";
import { useLocation } from "react-router";

export default function useScrollToAnchor() {
  const location = useLocation();

  React.useEffect(() => {
    const element = document.getElementById(location.hash.slice(1));
    if (element) {
      requestAnimationFrame(() => {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      });
    }
  }, [location.hash]);

  return null;
}
