import React from "react";
import { RouteObject } from "react-router";

import { z } from "zod";

import Solutions from "../pages/Solutions/Solutions";
import AllProjects from "../pages/Solutions/sections/AllProjects";
import OwnProjects from "../pages/Solutions/sections/OwnProjects";

export const SOLUTIONS_PATH = "auth";

export const solutionsPages = ["all", "own"] as const;
export const SolutionsPageZod = z.enum(solutionsPages);
export type SolutionsPage = z.infer<typeof SolutionsPageZod>;

const PageEnum = SolutionsPageZod.Enum;

export const solutionsRoute: RouteObject = {
  path: "",
  element: <Solutions />,
  children: [
    { index: true, element: <AllProjects /> },
    { path: PageEnum.own, element: <OwnProjects /> },
  ],
};

export function getSolutionsPath(): string {
  return `/${SOLUTIONS_PATH}`;
}

export function getOwnSolutionsPath(): string {
  return `/${SOLUTIONS_PATH}/${PageEnum.own}`;
}

export function getSolutionPath(solutionId: string | number): string {
  return `${getSolutionsPath()}/${solutionId}`;
}
