import React from "react";

import { useSolution } from "../../../api/solutions";
import ListCheckbox from "../../../components/form/ListCheckbox";
import { Partition } from "../../PrognosAI/models/partition";
import { Partitioner } from "../../PrognosAI/models/partitioner";
import { Analysis } from "../api/analysis";

interface DifferentiateBySelectorProps {
  id: string;
  label?: string;
  value: Analysis["diffPartitionerIds"];
  onChange(diffPartitionerIds: Analysis["diffPartitionerIds"]): void;
  sourcePartitionIds?: number[];
  disabled?: boolean;
}

export default function DifferentiateBySelector(
  props: DifferentiateBySelectorProps
): JSX.Element {
  const { id, label, value, onChange } = props;
  const { sourcePartitionIds, disabled } = props;

  const [{ data: solution }] = useSolution();

  const partitions = solution?.partitions ?? [];
  const partitioners = solution?.partitioners ?? [];

  const partitionerOptions = React.useMemo(
    () => getPartitionerOptions(partitions, partitioners, sourcePartitionIds),
    [
      partitions.map((p) => p.partitionId).join(","),
      partitioners.map((p) => p.partitionerId).join(","),
      sourcePartitionIds?.join(","),
    ]
  );

  return (
    <div className="my-2">
      <ListCheckbox
        id={id}
        label={label}
        size="small"
        options={partitionerOptions}
        value={partitionerOptions.filter((option) =>
          value.includes(option.key)
        )}
        onChange={(values) => onChange(values.map((val) => val.key))}
        disabled={disabled}
      />
    </div>
  );
}

function getPartitionerOptions(
  partitions: Partition[],
  [...partitioners]: Partitioner[],
  sourcePartitionIds: number[] | undefined
) {
  // direction: bottom-up (start with the leaves)
  partitioners.sort((a, b) => b.order - a.order);
  const partitionersAreas: { partitioner: Partitioner; areas: Partition[] }[] =
    [];
  // add areas to each partitioner, filter out areas not selected in the tree
  for (const partitioner of partitioners) {
    const lowerAreas = partitionersAreas.at(-1)?.areas ?? [];
    const areas = partitions
      .filter((p) => p.partitionerId === partitioner.partitionerId)
      .filter(
        (p) =>
          !sourcePartitionIds ||
          sourcePartitionIds.includes(p.partitionId) ||
          lowerAreas.some((lp) => lp.parentPartitionId === p.partitionId)
      );

    partitionersAreas.push({ partitioner, areas });
  }

  const partitionerOptions = partitionersAreas.map((p) => {
    const { partitionerId, name } = p.partitioner;
    // "differentiate by" by a partitioner does not make any difference if there's only one planning area at that level
    const disabled = new Set(p.areas.map((a) => a.name)).size <= 1;

    return {
      key: partitionerId,
      value: partitionerId,
      label: name,
      disabled: disabled,
      forceCheck: disabled,
    };
  });

  // reverse because the UI is top-down
  return partitionerOptions.reverse();
}
