import React from "react";
import { Outlet } from "react-router";

import { useSolution } from "../../api/solutions";
import useSolutionMessages from "../../hooks/useSolutionMessages";
import ErrorPage from "../../pages/ErrorPage/ErrorPage";

export default function SolutionAppLayout(): JSX.Element {
  const [solutionRes] = useSolution();

  // the user is informed about events from all the apps within the currently opened project
  useSolutionMessages();

  if (solutionRes.isError) {
    return <ErrorPage error={solutionRes.error} />;
  }

  return <Outlet />;
}
