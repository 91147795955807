import { useSearchParams } from "react-router";

import { parsePage, parsePageSize } from "../../../../api";

export default function useEndpointPage(): [
  //endpointKey: EndpointKey
  number | undefined,
  (page: number) => void,
  number | undefined,
] {
  const [searchParams, setSearchParams] = useSearchParams();

  const endpointKey = "";

  let pageValue: number | undefined = undefined;
  const page = searchParams.get(`${endpointKey}Page`);
  if (page) {
    pageValue = parsePage(page);
  }
  let pageSizeValue: number | undefined = undefined;
  const pageSize = searchParams.get(`${endpointKey}PageSize`);
  if (pageSize) {
    pageSizeValue = parsePageSize(pageSize);
  }

  const onChange = (page: number) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set(`${endpointKey}Page`, page.toString());
      return newParams;
    });
  };

  return [pageValue, onChange, pageSizeValue];
}
