import { TFunction } from "i18next";
import { z } from "zod";

import { Column } from "../components/datatable/types";
import { UserZod } from "./auth";
import { DateTimeZod } from "./primitives";

export const AuditZod = z.object({
  auditCreatedAt: DateTimeZod,
  auditCreatedBy: UserZod.nullable(),
  auditChangedAt: DateTimeZod.nullable(),
  auditChangedBy: UserZod.nullable(),
});
export type Audit = z.infer<typeof AuditZod>;

export function getAuditColumns(t: TFunction): Column[] {
  return [
    {
      key: "auditCreatedAt",
      label: t("Created at"),
      type: "datetime",
      filterable: true,
      sortable: true,
    },
    {
      key: "auditCreatedBy",
      apiKey: "auditCreatedBy.username",
      label: t("Created by"),
      type: "user",
      filterable: true,
      sortable: true,
    },
    {
      key: "auditChangedAt",
      label: t("Changed at"),
      type: "datetime",
      filterable: true,
      sortable: true,
    },
    {
      key: "auditChangedBy",
      apiKey: "auditChangedBy.username",
      label: t("Changed by"),
      type: "user",
      filterable: true,
      sortable: true,
    },
  ];
}
