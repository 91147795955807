import React from "react";
import { Link } from "react-router";

import ConditionalWrapper from "../../../../components/ConditionalWrapper";
import { HeroIcon } from "../../../../models/primitives";

interface SideMenuEntryProps {
  id: string;
  name: string;
  href?: string;
  onClick?(): void;
  icon: HeroIcon | ((props: React.ComponentProps<"svg">) => JSX.Element);
  active?: boolean;
  disabled?: boolean;
}

export default function SideMenuEntry(props: SideMenuEntryProps): JSX.Element {
  const { id, name, icon: Icon, active = false, disabled } = props;
  const { href, onClick } = props;

  const realDisabled = disabled || (!href && !onClick);

  return (
    <ConditionalWrapper
      condition={!disabled && !!href}
      wrapper={(children) => (
        <Link to={href ?? ""} className="no-underline">
          {children}
        </Link>
      )}
    >
      <span
        id={`sideMenuEntry${id[0].toUpperCase()}${id.slice(1)}`}
        onClick={!realDisabled ? onClick : undefined}
        className={classNames(
          "group rounded-md px-3 py-2 flex items-center text-sm font-medium no-underline",
          active
            ? "bg-gray-50 text-blue-700 hover:text-blue-700 hover:bg-white"
            : realDisabled
              ? "cursor-default text-gray-500"
              : "cursor-pointer text-gray-900 hover:text-gray-900 hover:bg-gray-50"
        )}
      >
        <Icon
          className={classNames(
            active
              ? "text-blue-500 group-hover:text-blue-500"
              : "text-gray-400",
            !active && !realDisabled ? "group-hover:text-gray-500" : "",
            "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          )}
        />
        <span className="truncate">{name}</span>
      </span>
    </ConditionalWrapper>
  );
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
