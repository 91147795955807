import React from "react";

import classNames from "classnames";

import SearchField from "../../../../../components/SearchField";
import StackedList, {
  StackedListItem,
} from "../../../../../components/StackedList";
import { Partition } from "../../../../PrognosAI/models/partition";

export type SearchablePartition<T> = Pick<Partition, "name"> & {
  partitionId: T;
};

interface SearchablePartitionsListProps<T> {
  id?: string;
  label?: string;
  partitions: SearchablePartition<T>[];
  selectedArea: SearchablePartition<T> | undefined;
  onSelect(id: T): void;
}

export default function SearchablePartitionsList<T>(
  props: SearchablePartitionsListProps<T>
): JSX.Element {
  const { id = "", label, partitions, selectedArea, onSelect } = props;

  const [searchValue, setSearchValue] = React.useState("");

  const visiblePartitions = searchValue
    ? partitions.filter((p) =>
        p.name.toLowerCase().includes(searchValue.toLowerCase())
      )
    : partitions;

  const scrollToPartition = (partition: SearchablePartition<T>) => {
    const element = document.getElementById(
      `${id ? id + "-" : ""}listedPartition-${partition.partitionId}`
    );
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  const [scrolled, setScrolled] = React.useState(false);
  React.useEffect(() => {
    if (!selectedArea || scrolled) {
      return;
    }
    scrollToPartition(selectedArea);
    setScrolled(true);
  }, [selectedArea, scrolled]);

  const handleSearchClear = () => {
    setSearchValue("");
    requestAnimationFrame(() => {
      if (selectedArea) {
        scrollToPartition(selectedArea);
      }
    });
  };

  return (
    <>
      <SearchField
        id={`${id ? id + "-" : ""}partitionsSearchField`}
        label={label}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        onClear={handleSearchClear}
      />
      <StackedList className="grow overflow-auto">
        {visiblePartitions.map((p, index) => (
          <StackedListItem
            key={
              typeof p.partitionId === "number" ||
              typeof p.partitionId === "string"
                ? p.partitionId
                : index
            }
            id={`${id ? id + "-" : ""}listedPartition-${p.partitionId}`}
            className={classNames(
              "cursor-pointer",
              p.partitionId === selectedArea?.partitionId
                ? "bg-blue-50 hover:bg-blue-100"
                : ""
            )}
            onClick={() => onSelect(p.partitionId)}
          >
            {p.name}
          </StackedListItem>
        ))}
      </StackedList>
    </>
  );
}
