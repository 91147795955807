import React from "react";
import { useTranslation } from "react-i18next";

import { useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { solutionQuery } from "../../../../../api/solutions";
import { Message } from "../../../../PrognosAI/models/message";

const DirtyAggregatesArgsZod = z.object({
  solutionId: z.number(),
});

export default function useDirtyAggregatesMessageCallback() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const callback = React.useCallback(
    (msg: Message) => {
      const parser = DirtyAggregatesArgsZod.safeParse(msg.eventArgs);
      if (!parser.success) {
        return;
      }

      const args = parser.data;

      queryClient.invalidateQueries(solutionQuery(args.solutionId));
    },
    [queryClient, t]
  );

  return callback;
}
