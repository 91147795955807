import React from "react";
import { useTranslation } from "react-i18next";

import { TrashIcon } from "@heroicons/react/24/outline";

import { EndpointOptions } from "../../../api";
import {
  solutionsQuery,
  useDeleteSingleSolution,
} from "../../../api/solutions";
import useEndpoint from "../../../apps/PrognosAI/hooks/useEndpoint";
import useSearchBoolean from "../../../apps/PrognosAI/hooks/useSearchBoolean";
import {
  SolutionPhaseZod,
  solutionPhases,
} from "../../../apps/PrognosAI/models/solution";
import PhaseIcon from "../../../apps/PrognosAI/pages/Dashboard/components/PhaseIcon";
import { tenum } from "../../../apps/PrognosAI/services/translationMappings";
import Card from "../../../components/Card";
import DataTable from "../../../components/DataTable";
import ConfirmationButton from "../../../components/buttons/ConfirmationButton";
import { getAuditColumns } from "../../../models/audit";
import { FilterSetting } from "../../../models/primitives";
import { getSolutionPath } from "../../../routes/solutions";
import { DEFAULT_PAGE_SIZE } from "../../../services/constants";
import ErrorPage from "../../ErrorPage/ErrorPage";

const DEFAULT_ENDPOINT_OPTIONS: EndpointOptions = {
  sort: [["name", "asc"]],
  pageSize: DEFAULT_PAGE_SIZE,
};

interface ProjectsTableProps {
  id: string;
  filter?: FilterSetting[];
}

export default function ProjectsTable(props: ProjectsTableProps): JSX.Element {
  const { id, filter = [] } = props;

  const [showArchived] = useSearchBoolean("showArchived");

  const { t } = useTranslation();

  const endpoint = useEndpoint(
    "Solutions",
    (opts) =>
      solutionsQuery({
        ...opts,
        filter: (opts.filter ?? [])
          .concat(filter)
          .filter((f) => !showArchived || f[0] !== "phase")
          .concat([
            [
              "phase",
              showArchived ? "=" : "!=",
              SolutionPhaseZod.Enum.Archived,
            ],
          ]),
      }),
    DEFAULT_ENDPOINT_OPTIONS
  );
  const { queryResult: solutionsQ, options } = endpoint;
  const { onSortChange, onFilterChange } = endpoint;
  const { items: solutions = [], count } = solutionsQ.data ?? {};
  const { isLoading, isError, error, isPlaceholderData } = solutionsQ;

  const deleteSolution = useDeleteSingleSolution();

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <Card>
      <DataTable
        id={id}
        rowKey="solutionId"
        linkKey="link"
        rows={solutions.map((s) => ({
          ...s,
          link: getSolutionPath(s.solutionId),
          phase: <PhaseIcon phase={s.phase} />,
          dataInterval:
            s.dataInterval && s.dataInterval !== "Invalid"
              ? s.dataInterval
              : "",
        }))}
        columns={[
          {
            key: "phase",
            type: "enum",
            label: t("Stage"),
            sortable: true,
            filterable: true,
            content: "icon",
            options: {
              enumOptions: solutionPhases
                .filter((key) => showArchived || key !== "Archived")
                .map((key) => ({
                  key,
                  label: tenum(`SolutionPhase:${key}`, t),
                })),
            },
          },
          {
            key: "name",
            label: t("Name"),
            type: "string",
            highlighted: true,
            filterable: true,
            sortable: true,
            shrink: "truncate",
          },
          ...getAuditColumns(t),
          {
            key: "lastImportDate",
            label: t("Last history import"),
            type: "datetime",
            filterable: true,
            sortable: true,
          },
          {
            key: "lastResultDate",
            label: t("Last Prognos AI result"),
            type: "datetime",
            filterable: true,
            sortable: true,
          },
        ]}
        actions={[
          (row) => (
            <ConfirmationButton
              id={`deleteSolution-${row.solutionId}`}
              dialogTitle={t("Delete project")}
              dialogQuestion={t(
                "Are you sure you want to delete the project '{{name}}'?",
                { name: row.name }
              )}
              onConfirm={() => deleteSolution.mutate(row.solutionId)}
            >
              {(props) => (
                <button {...props} title={t("Delete project")}>
                  <TrashIcon className="text-gray-400 hover:text-gray-600 h-5 w-5" />
                </button>
              )}
            </ConfirmationButton>
          ),
        ]}
        showSkeleton={isLoading || isPlaceholderData}
        page={options.page}
        pageSize={options.pageSize}
        totalCount={count}
        sort={options.sort}
        filter={options.filter}
        onFilter={onFilterChange}
        onSort={onSortChange}
      />
    </Card>
  );
}
