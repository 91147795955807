import { useParams } from "react-router";

import { EndpointKey } from "./useEndpointOptions";

export default function useParametrizedEndpointKey(
  endpointKey: EndpointKey
): EndpointKey {
  // make different settings in different projects and entities possible
  const params = useParams();
  const keys = Object.keys(params);
  if (keys.length > 0) {
    endpointKey = `${endpointKey}:${keys.map((key) => params[key] ?? "").join("/")}`;
  }

  return endpointKey;
}
