import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "../../../api";
import { solutionQuery } from "../../../api/solutions";
import { getChartDetailPath } from "../../DataStore/routes/review";
import {
  Chart,
  ChartDetail,
  ChartDetailDraft,
  ChartDetailZod,
  ChartStatisticsResult,
  ChartStatisticsResultZod,
  ChartStatisticsTask,
  ChartStatisticsTaskZod,
} from "../models/chart";
import { ChartSeries } from "../models/series";

export const CHARTS_API = "/Datastore/Charts";

async function getChart(chartId: string): Promise<ChartDetail> {
  return ChartDetailZod.parse((await api.get(`${CHARTS_API}/${chartId}`)).data);
}

export const chartQuery = (chartId: string) => ({
  queryKey: ["chart", chartId],
  queryFn: async () => {
    if (chartId === "new") {
      return null;
    }
    return await getChart(chartId);
  },
});

export const removeSeriesData = (series: ChartSeries): ChartSeries => {
  return { ...series, data: undefined };
};

const removeChartData = (chart: ChartDetailDraft): ChartDetailDraft => {
  return {
    ...chart,
    series: chart.series.map((series) => removeSeriesData(series)),
  };
};

async function createChart(
  solutionId: string,
  chart: ChartDetailDraft
): Promise<ChartDetail> {
  return ChartDetailZod.parse(
    (
      await api.post(
        `/Datastore/Solutions/${solutionId}/Charts`,
        removeChartData(chart)
      )
    ).data
  );
}

export const useCreateChart = (solutionId: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (chart: ChartDetailDraft) => createChart(solutionId, chart),
    onSuccess: (data) => {
      toast.success(t("Chart saved successfully."));
      navigate(getChartDetailPath(solutionId, data.chartId));
      queryClient.invalidateQueries(solutionQuery(solutionId));
    },
    onError: () => {
      toast.error(t("An error occurred while saving. Please try again."));
    },
  });
};

async function updateChart(chartId: string, chart: ChartDetail) {
  // removing the series from the request for better performance
  chart = { ...chart, series: [] };
  return ChartDetailZod.omit({ series: true }).parse(
    (await api.patch(`${CHARTS_API}/${chartId}`, removeChartData(chart))).data
  );
}

export const useEditChart = (solutionId: string, chartId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (chart: ChartDetail) => updateChart(chartId, chart),
    onSuccess: () => {
      queryClient.invalidateQueries(solutionQuery(solutionId));
      queryClient.invalidateQueries(chartQuery(chartId));
      queryClient.invalidateQueries(chartStatisticsQuery(chartId));
    },
    onError: () => {
      toast.error(t("The changes were not saved. Please try again."));
    },
  });
};

async function copyChart(chartId: string | number) {
  return ChartDetailZod.parse(
    (await api.post(`${CHARTS_API}/${chartId}/Copy`)).data
  );
}

export const useCopyChart = (solutionId: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: copyChart,
    onSuccess: (chart) => {
      queryClient.invalidateQueries(solutionQuery(solutionId));
      navigate(getChartDetailPath(solutionId, chart.chartId));
    },
    onError: () => {
      toast.error(t("An error occurred while copying. Please try again."));
    },
  });
};

async function deleteChart(chartId: string) {
  return api.delete(`${CHARTS_API}/${chartId}`);
}

export const useDeleteChart = (solutionId: string, currentChartId = NaN) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (chart: Chart) => deleteChart(chart.chartId.toString()),
    onSuccess: (_, chart) => {
      toast.success(t("Chart deleted successfully."));
      queryClient.invalidateQueries(solutionQuery(solutionId));
      queryClient.removeQueries(chartQuery(chart.chartId.toString()));
      if (chart.chartId === currentChartId) {
        navigate(getChartDetailPath(solutionId, "new"));
      }
    },
    onError: () => {
      toast.error(t("An error occurred while deleting. Please try again."));
    },
  });
};

async function generateChartStatistics(
  chartId: number | string
): Promise<ChartStatisticsResult | ChartStatisticsTask> {
  const { data } = await api.post(
    `${CHARTS_API}/${chartId}/GenerateStatistics`
  );

  if (Object.keys(data).length > 1) {
    return ChartStatisticsResultZod.parse(data);
  }

  return ChartStatisticsTaskZod.parse(data);
}

export const chartStatisticsQuery = (chartId: number | string) => ({
  queryKey: ["chartStatistics", chartId.toString()],
  queryFn: async () => await generateChartStatistics(chartId),
});
