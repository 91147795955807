import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router";

import { useSolution } from "../../../api/solutions";
import Slideover from "../../../components/Slideover";
import Select from "../../../components/form/Select";
import SearchablePartitionsList, {
  SearchablePartition,
} from "../../DataStore/pages/Review/components/SearchablePartitionsList";
import { AdHocPartition, getAdHocPartitionName } from "../api/analysis";

interface AdHocPartitionSlideoverProps {
  open: boolean;
  availablePartitions: AdHocPartition[];
  adHocPartitionId: number | string;
  onClose(): void;
}

export default function AdHocPartitionSlideover(
  props: AdHocPartitionSlideoverProps
): JSX.Element {
  const { open, onClose } = props;

  const { t } = useTranslation();

  const { partitions, selectedPartition } = usePartitions(props);

  const [searchParams, setSearchParams] = useSearchParams();
  const handlePartitionChange = (adHocPartitionId: number | string) => {
    searchParams.set("adHocPartitionId", adHocPartitionId.toString());
    setSearchParams(searchParams);
  };

  return (
    <Slideover
      title={t("Select a planning area")}
      open={open}
      onClose={onClose}
    >
      <div className="space-y-4">
        {partitions.length <= 1 && (
          <div>
            <Select
              id="pmPartition"
              label={t("Planning area")}
              options={partitions}
              value={selectedPartition}
              keyProp="partitionId"
              labelProp="name"
              onChange={({ partitionId }) => handlePartitionChange(partitionId)}
              disabled
            />
          </div>
        )}
        {partitions.length > 1 && (
          <div
            className="flex flex-col space-y-4"
            style={{ height: "calc(100vh - 100px)" }}
          >
            <SearchablePartitionsList
              id="pmPartition"
              label={t("Planning area")}
              partitions={partitions}
              selectedArea={selectedPartition ?? undefined}
              onSelect={handlePartitionChange}
            />
          </div>
        )}
      </div>
    </Slideover>
  );
}
function usePartitions({
  availablePartitions,
  adHocPartitionId,
}: AdHocPartitionSlideoverProps): {
  partitions: SearchablePartition<number | string>[];
  selectedPartition: SearchablePartition<number | string> | null;
} {
  const { t } = useTranslation();
  const [{ data: solution }] = useSolution();

  const partitions = availablePartitions.map((p) => ({
    partitionId: p.adHocPartitionId,
    name: getAdHocPartitionName(p, solution?.partitioners ?? [], t),
  }));

  const selectedPartition =
    partitions.find((p) => p.partitionId === adHocPartitionId) ?? null;
  return { partitions, selectedPartition };
}
