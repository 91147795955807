import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "../../../api";
import { AlgorithmConfig, AlgorithmConfigZod } from "../models/algorithmConfig";
import { ModelDetail } from "../models/model";
import { MODELS_API, modelQuery } from "./models";

const ALGOS_API = "/Prognos/AlgorithmConfigs";

async function getAlgorithmConfig(
  algorithmConfigId: string | number
): Promise<AlgorithmConfig> {
  return AlgorithmConfigZod.parse(
    (await api.get(`${ALGOS_API}/${algorithmConfigId}`)).data
  );
}

export const algorithmConfigQuery = (algorithmConfigId: string | number) => ({
  queryKey: ["algorithmConfig", algorithmConfigId.toString()],
  queryFn: () => getAlgorithmConfig(algorithmConfigId),
});

async function createAlgorithmConfig(
  modelId: string,
  algorithmConfig: AlgorithmConfig
): Promise<AlgorithmConfig> {
  return AlgorithmConfigZod.parse(
    (
      await api.post(
        `${MODELS_API}/${modelId}/AlgorithmConfigs`,
        algorithmConfig
      )
    ).data
  );
}

export const useCreateAlgorithm = (modelId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (algorithm: AlgorithmConfig) =>
      createAlgorithmConfig(modelId, algorithm),
    onSuccess: () => {
      queryClient.invalidateQueries(modelQuery(modelId));
    },
    onError: () => {
      toast.error(t("An error occurred while saving. Please try again."));
    },
  });
};

async function updateAlgorithmConfig(
  algorithmConfigId: string | number,
  patch: Partial<AlgorithmConfig>
) {
  return AlgorithmConfigZod.parse(
    (await api.patch(`${ALGOS_API}/${algorithmConfigId}`, patch)).data
  );
}

function updateModelAlgorithmCallback(newAlgorithm: AlgorithmConfig) {
  return (prevModel: ModelDetail | undefined) => {
    if (!prevModel) {
      return prevModel;
    }

    const newModel: ModelDetail = {
      ...prevModel,
      algorithmConfigs: [...prevModel.algorithmConfigs],
    };
    const index = newModel.algorithmConfigs.findIndex(
      (alg) => alg.algorithmConfigId === newAlgorithm.algorithmConfigId
    );
    if (index === -1) {
      return newModel;
    }
    newModel.algorithmConfigs.splice(index, 1, newAlgorithm);

    return newModel;
  };
}

export const useEditAlgorithm = (
  modelId: string | number,
  algorithmConfigId: string | number,
  reloadModel = false,
  retriesOnError = true
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (patch: Partial<AlgorithmConfig>) =>
      updateAlgorithmConfig(algorithmConfigId, patch),
    onSuccess: (newAlgorithm) => {
      if (reloadModel) {
        queryClient.invalidateQueries(modelQuery(modelId));
      } else {
        queryClient.setQueryData<ModelDetail>(
          modelQuery(modelId).queryKey,
          updateModelAlgorithmCallback(newAlgorithm)
        );
      }
    },
    onError: () => {
      toast.error(
        retriesOnError
          ? t(
              "The changes were not saved. Please wait or refresh the page to start over."
            )
          : t("The changes were not saved. Please try again.")
      );
    },
  });
};

export const useEditSomeAlgorithm = (modelId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: ({
      algorithmConfigId,
      patch,
    }: {
      algorithmConfigId: number | string;
      patch: Partial<AlgorithmConfig>;
    }) => updateAlgorithmConfig(algorithmConfigId.toString(), patch),
    onSuccess: (newAlgorithm) => {
      queryClient.setQueryData<ModelDetail>(
        ["model", modelId],
        updateModelAlgorithmCallback(newAlgorithm)
      );
    },
    onError: () => {
      toast.error(t("The changes were not saved. Please try again."));
    },
  });
};

export const useEditAlgorithms = (modelId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: ({
      algorithmConfigId,
      patch,
    }: {
      algorithmConfigId: number | string;
      patch: Partial<AlgorithmConfig>;
    }) => updateAlgorithmConfig(algorithmConfigId.toString(), patch),
    onSuccess: (newAlgorithm) => {
      queryClient.setQueryData<ModelDetail>(
        ["model", modelId],
        updateModelAlgorithmCallback(newAlgorithm)
      );
    },
    onError: () => {
      toast.error(t("An error has occurred. Please try again."));
    },
  });
};

async function deleteAlgorithmConfig(algorithmConfigId: number) {
  return api.delete(`${ALGOS_API}/${algorithmConfigId}`);
}

export const useDeleteAlgorithm = (modelId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (algorithmId: number) => deleteAlgorithmConfig(algorithmId),
    onSuccess: (_, algorithmConfigId) => {
      queryClient.invalidateQueries(modelQuery(modelId));
      queryClient.removeQueries(algorithmConfigQuery(algorithmConfigId));
    },
    onError: () => {
      toast.error(t("An error occurred while deleting. Please try again."));
    },
  });
};
