import React from "react";
import { useTranslation } from "react-i18next";

import { useQuery } from "@tanstack/react-query";

import { useSolution } from "../../../api/solutions";
import ListRadio from "../../../components/form/ListRadio";
import Select from "../../../components/form/Select";
import NarrowFormItem from "../../PrognosAI/components/narrowform/NarrowFormItem";
import { getMeasurementNameUnit } from "../../PrognosAI/models/measurement";
import {
  Analysis,
  DEFAULT_ANALYSIS,
  analysisMinMaxDatesQuery,
} from "../api/analysis";
import DifferentiateBySelector from "./DifferentiateBySelector";
import NarrowConvertValuesSelect from "./NarrowConvertValuesSelect";
import NarrowPartitionsSelect from "./NarrowPartitionsSelect";
import NarrowTimeRangeFields from "./NarrowTimeRangeFields";
import NarrowValueRangeFields from "./NarrowValueRangeFields";

interface DataSettingsProps {
  analysis: Analysis;
  onChange(patch: Partial<Analysis>): void;
  contained?: boolean;
}

export default function DataSettings(props: DataSettingsProps): JSX.Element {
  const { analysis, onChange, contained = false } = props;

  const [{ data: solution }] = useSolution();

  const { t } = useTranslation();

  const { data: minMaxDates } = useQuery(
    analysisMinMaxDatesQuery(analysis.analysisId)
  );

  const measurementOptions =
    solution?.measurements.map((m) => ({
      key: m.measurementId,
      value: m.measurementId,
      label: getMeasurementNameUnit(m),
    })) ?? [];
  const usedMeasurement = measurementOptions.find(
    (option) => option.key === analysis.measurementId
  );
  const disabledMeasurements =
    measurementOptions.length === 1 && !!usedMeasurement;

  const unit = solution?.measurements.find(
    (m) => m.measurementId === analysis.measurementId
  )?.unit;

  return (
    <>
      <NarrowFormItem
        label={t("Observable")}
        htmlFor="measurementId"
        isDefault={disabledMeasurements}
        forceNarrow={contained}
        standalone={contained}
      >
        {!contained && (
          <div className="my-2">
            <ListRadio
              id="measurementId"
              options={measurementOptions}
              value={usedMeasurement ?? null}
              disabled={disabledMeasurements}
              onChange={({ key }) => onChange({ measurementId: key })}
            />
          </div>
        )}
        {contained && (
          <Select
            id="measurementId"
            options={measurementOptions}
            value={usedMeasurement ?? null}
            disabled={disabledMeasurements}
            onChange={({ key }) => onChange({ measurementId: key })}
          />
        )}
      </NarrowFormItem>
      {!!solution && (
        <NarrowPartitionsSelect
          id="planningAreasPicker"
          solution={solution}
          isContained={contained}
          partitionIds={analysis.partitionIds}
          onChange={(partitionIds) => onChange({ partitionIds })}
        />
      )}
      <NarrowFormItem
        label={t("Differentiate by")}
        htmlFor="diffPartitionerIds"
        forceNarrow={contained}
        standalone={contained}
      >
        <DifferentiateBySelector
          id="diffPartitionerIds"
          value={analysis.diffPartitionerIds}
          onChange={(diffPartitionerIds) => onChange({ diffPartitionerIds })}
          sourcePartitionIds={analysis.partitionIds}
        />
      </NarrowFormItem>
      <NarrowConvertValuesSelect
        id="convertValues"
        isContained={contained}
        value={analysis.convertValues}
        onChange={(convertValues) => onChange({ convertValues })}
        isDefault={
          !contained &&
          analysis.convertValues !== DEFAULT_ANALYSIS.convertValues
        }
      />
      <NarrowTimeRangeFields
        id="timeRange"
        isContained={contained}
        fromDate={analysis.fromDate}
        toDate={analysis.toDate}
        onChange={onChange}
        minMaxDates={minMaxDates}
      />
      <NarrowValueRangeFields
        id="valueRange"
        isContained={contained}
        minValue={analysis.minValue}
        maxValue={analysis.maxValue}
        onChange={onChange}
        unit={unit}
      />
    </>
  );
}
