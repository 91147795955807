import React from "react";
import { useTranslation } from "react-i18next";

import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";

import EntityExportButton from "../../../apps/DataStore/pages/BusinessHours/components/EntityExportButton";
import StandardExportButton from "../../../apps/DataStore/pages/BusinessHours/components/StandardExportButton";
import TimestampZonePicker from "../../../apps/DataStore/pages/DataImportConfig/components/TimestampZonePicker";
import { hasData } from "../../../apps/PrognosAI/functions/solution";
import { ImportExportConfig } from "../../../apps/PrognosAI/models/importExport";
import { SolutionDetail } from "../../../apps/PrognosAI/models/solution";
import HeroActionButton from "../../../components/HeroActionButton";
import LargeTimescaleButtons from "../../../components/LargeTimescaleButtons";
import Slideover from "../../../components/Slideover";
import ListCheckbox from "../../../components/form/ListCheckbox";

interface HistoryExportButtonProps {
  solution: SolutionDetail;
  type: "hero" | "standard";
  className?: string;
}

type HistoryExportState = Pick<
  ImportExportConfig,
  "timestampZone" | "resultTimeScale" | "includeAggregates"
>;

export default function HistoryExportButton(
  props: HistoryExportButtonProps
): JSX.Element {
  const { solution, className, type } = props;

  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState<HistoryExportState>({
    timestampZone: "Local",
    resultTimeScale: "1D",
    includeAggregates: false,
  });

  const handleClick = () => {
    setOpen(true);
  };

  const title = t("Export history data");
  const noDataTitle = t("The project does not have any data");

  const hasNoData = !hasData(solution);

  return (
    <>
      {type === "hero" && (
        <HeroActionButton
          id="exportHistoryDataButton"
          title={hasNoData ? noDataTitle : title}
          onClick={handleClick}
          className={className}
          disabled={hasNoData || open}
        >
          <ArrowDownTrayIcon />
        </HeroActionButton>
      )}
      {type === "standard" && (
        <StandardExportButton
          id="exportHistoryDataButton"
          className={className}
          title={hasNoData ? noDataTitle : undefined}
          onClick={handleClick}
          disabled={hasNoData || open}
        >
          {title}
        </StandardExportButton>
      )}
      {!hasNoData && (
        <Slideover
          title={t("History export settings")}
          open={open}
          onClose={() => setOpen(false)}
        >
          <div className="space-y-4">
            <div>
              <TimestampZonePicker
                id="timestampZone"
                label={t("Time zone")}
                solution={solution}
                value={state.timestampZone}
                onChange={(timestampZone) =>
                  setState((prev) => ({ ...prev, timestampZone }))
                }
              />
            </div>
            <div>
              <LargeTimescaleButtons
                id="scaleButtons"
                label={t("Time scale")}
                options={solution.aggregates}
                value={state.resultTimeScale ?? null}
                onChange={(resultTimeScale) =>
                  setState((prev) => ({ ...prev, resultTimeScale }))
                }
              />
            </div>
            <div className="py-2">
              <ListCheckbox
                id="includeAggregates"
                options={[
                  {
                    key: "includeAggregates",
                    value: "includeAggregates",
                    label: t("Include planning area aggregates"),
                  },
                ]}
                value={
                  state.includeAggregates
                    ? [{ key: "includeAggregates", value: "includeAggregates" }]
                    : []
                }
                onChange={(values) =>
                  setState((prev) => ({
                    ...prev,
                    includeAggregates: values.some(
                      (v) => v.key === "includeAggregates"
                    ),
                  }))
                }
              />
            </div>
            <div className="text-right">
              <EntityExportButton
                id="confirmHistoryExportButton"
                entity="History"
                type="standard"
                variant="primary"
                config={state}
                hideIcon
              />
            </div>
          </div>
        </Slideover>
      )}
    </>
  );
}
