import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import SideMenu from "../../../../PrognosAI/components/sidemenu/SideMenu";
import SideMenuEntry from "../../../../PrognosAI/components/sidemenu/SideMenuEntry";
import { DataImportConfigDetail } from "../../../../PrognosAI/models/dataImportConfig";
import {
  DatasetPage,
  DatasetPageZod,
  datasetPages,
} from "../../../routes/import";
import useMappingsCheck from "../hooks/useMappingsCheck";

interface DataImportConfigMenuProps {
  dataset: DataImportConfigDetail | undefined;
}

export default function DataImportConfigMenu(
  props: DataImportConfigMenuProps
): JSX.Element {
  const { dataset } = props;

  const pages = usePages(dataset);
  const page = useDatasetPage();

  return (
    <SideMenu>
      {pages.map((item) => (
        <SideMenuEntry
          key={item.id}
          id={item.id}
          name={item.name}
          href={`./${item.id}`}
          icon={item.icon}
          active={item.id === page}
          disabled={item.disabled}
        />
      ))}
    </SideMenu>
  );
}

interface NavItem {
  id: DatasetPage;
  name: string;
  icon: (props: React.ComponentProps<"svg">) => JSX.Element;
  disabled?: boolean;
}

const usePages = (dataset: DataImportConfigDetail | undefined): NavItem[] => {
  const { t } = useTranslation();

  const missingMappings = useMappingsCheck(dataset);

  const noFile = !dataset || (dataset.source === "File" && !dataset.fileName);
  const notAnalyzed = !dataset?.isAnalyzed;
  const hasMissing = !dataset || missingMappings.length > 0;

  const page = useDatasetPage();
  const pageIndex = datasetPages.indexOf(page);

  return [
    {
      id: "source",
      name: t("Data source"),
      icon: getCharIcon(pageIndex < 1 ? 1 : "✓"),
    },
    {
      id: "mapping",
      name: t("Column mappings"),
      icon: getCharIcon(pageIndex < 2 ? 2 : "✓"),
      disabled: noFile || notAnalyzed,
    },
    {
      id: "timestamps",
      name: t("Timestamp settings"),
      icon: getCharIcon(pageIndex < 3 ? 3 : "✓"),
      disabled: noFile || notAnalyzed || hasMissing,
    },
    {
      id: "aggregates",
      name: t("Observables' settings"),
      icon: getCharIcon(pageIndex < 4 ? 4 : "✓"),
      disabled: noFile || notAnalyzed || hasMissing,
    },
    {
      id: "import",
      name: t("Data import"),
      icon: getCharIcon(5),
      disabled: noFile || notAnalyzed || hasMissing,
    },
  ];
};

export const useDatasetPage = (): DatasetPage => {
  const location = useLocation();
  const paths = location.pathname.split("/");

  return DatasetPageZod.catch(DatasetPageZod.Enum.timestamps).parse(
    paths[paths.length - 1]
  );
};

export const getCharIcon =
  (char: number | string) => (props: React.ComponentProps<"svg">) => (
    <svg {...props}>
      <circle
        r="47%"
        cx="50%"
        cy="50%"
        stroke="currentColor"
        strokeWidth="1.5"
        fill="none"
      />
      <text x="50%" y="70%" textAnchor="middle" fill="currentColor">
        {char}
      </text>
    </svg>
  );
