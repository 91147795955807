import React from "react";
import { useTranslation } from "react-i18next";

import { useQueryClient } from "@tanstack/react-query";

import { solutionQuery } from "../../../../../api/solutions";
import {
  activeAggregateCalcTasksQuery,
  failedOrFinishedAggregateTasksQuery,
} from "../../../../PrognosAI/api/tasks";
import { Message } from "../../../../PrognosAI/models/message";
import {
  GeneralSolutionTaskZod,
  TaskStatus,
  finalTaskStates,
} from "../../../../PrognosAI/models/task";

export default function useAggregatesCalcMessageCallback() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const callback = React.useCallback(
    (msg: Message) => {
      if (
        msg.eventName !== "TaskUpdate" ||
        msg.eventArgs.taskName !== "CalculateAggregates"
      ) {
        return;
      }

      const task = GeneralSolutionTaskZod.parse(msg.eventArgs);
      const solutionId = task.solutionId.toString();

      const continuousKeysToInvalidate = [
        activeAggregateCalcTasksQuery(solutionId),
      ];

      for (const query of continuousKeysToInvalidate) {
        queryClient.invalidateQueries(query);
      }

      const finalKeysToInvalidate = [
        solutionQuery(solutionId),
        failedOrFinishedAggregateTasksQuery(solutionId),
        { queryKey: ["seriesData"] }, // seriesDataQuery
        { queryKey: ["segmentInfo", solutionId] }, // segmentInfoQuery
        { queryKey: ["segmentHeatmap", solutionId] }, // segmentHeatmapQuery
      ];

      if ((finalTaskStates as readonly TaskStatus[]).includes(task.status)) {
        for (const query of finalKeysToInvalidate) {
          queryClient.invalidateQueries(query);
        }
      }
    },
    [queryClient, t]
  );

  return callback;
}
