import React from "react";
import { RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getBusinessMapPath } from "./businessMap";

const Variables = lazyWithRetry(() => import("../pages/Variables/Variables"));

const VARIABLES_PATH = "variables";

export const variablesRoute: RouteObject = {
  path: VARIABLES_PATH,
  element: <Variables />,
};

export function getVariablesPath(
  solutionId: string | number,
  businessMapId: string | number
): string {
  return `${getBusinessMapPath(solutionId, businessMapId)}/${VARIABLES_PATH}`;
}
