import React from "react";
import { RouteObject } from "react-router";

import { analyzerRoute } from "../apps/Analyzer/routes";
import { getAnalyzerPath } from "../apps/Analyzer/routes/analyzer";
import { dataStoreRoute } from "../apps/DataStore/routes";
import { demandRoute } from "../apps/Demand/routes";
import { getDemandPath } from "../apps/Demand/routes/demand";
import BasicLayout from "../apps/PrognosAI/components/layouts/BasicLayout";
import { prognosRoute } from "../apps/PrognosAI/routes";
import { getPrognosPath } from "../apps/PrognosAI/routes/prognos";
import FirstLevelLayout from "../components/layouts/FirstLevelLayout";
import SolutionAppLayout from "../components/layouts/SolutionAppLayout";
import SolutionLayout from "../components/layouts/SolutionLayout";
import AppsOverview from "../pages/AppsOverview/AppsOverview";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import Login from "../pages/Login/Login";
import RequireAuth from "../pages/Login/RequireAuth";
import NotFound from "../pages/NotFound/NotFound";
import { solutionSettingsRoute } from "./solutionSettings";
import { SOLUTIONS_PATH, solutionsRoute } from "./solutions";
import { systemRoute } from "./system";
import { tasksRoute } from "./tasks";
import { userSettingsRoute } from "./userSettings";

const routes: RouteObject[] = [
  {
    path: "/",
    errorElement: (
      <BasicLayout className="h-full">
        <ErrorPage />
      </BasicLayout>
    ),
    children: [
      {
        index: true,
        element: (
          <BasicLayout className="h-full">
            <Login />
          </BasicLayout>
        ),
      },
      {
        path: SOLUTIONS_PATH,
        element: (
          <RequireAuth>
            <FirstLevelLayout />
          </RequireAuth>
        ),
        children: [solutionsRoute, userSettingsRoute, tasksRoute, systemRoute],
      },
      {
        path: `${SOLUTIONS_PATH}/:solutionId`,
        element: (
          <RequireAuth>
            <SolutionLayout />
          </RequireAuth>
        ),
        children: [
          { index: true, element: <AppsOverview /> },
          solutionSettingsRoute,
          userSettingsRoute,
          tasksRoute,
        ],
      },
      {
        path: `${SOLUTIONS_PATH}/:solutionId`,
        element: (
          <RequireAuth>
            <SolutionAppLayout />
          </RequireAuth>
        ),
        children: [dataStoreRoute, analyzerRoute, prognosRoute, demandRoute],
      },
    ],
  },
  {
    path: "*",
    element: (
      <RequireAuth>
        <BasicLayout className="h-full">
          <NotFound />
        </BasicLayout>
      </RequireAuth>
    ),
  },
];

export default routes;

const suiteApps = ["Analyzer", "PrognosAI", "Demand"] as const;
export type SuiteApp = (typeof suiteApps)[number];

export function getAppPathPrefix(
  solutionId: string | number,
  app: SuiteApp
): string {
  switch (app) {
    case "Analyzer":
      return getAnalyzerPath(solutionId);
    case "PrognosAI":
      return getPrognosPath(solutionId);
    case "Demand":
      return getDemandPath(solutionId);
  }
}

export function getAppByPrefix(
  solutionId: string | number,
  path: string
): SuiteApp | undefined {
  for (const app of suiteApps) {
    const prefix = getAppPathPrefix(solutionId, app);
    if (path.startsWith(prefix)) {
      return app;
    }
  }

  return undefined;
}

export function getAppName(app: SuiteApp): string {
  switch (app) {
    case "Analyzer":
      return "Analyzer";
    case "Demand":
      return "Demand";
    case "PrognosAI":
      return "Prognos AI";
  }
}
