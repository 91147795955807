import { z } from "zod";

import { AggregateZod } from "../../../models/aggregate";
import { AuditZod } from "../../../models/audit";
import { DateTimeZod } from "../../../models/primitives";
import { ChartZod } from "./chart";
import { SamplingPeriodZod } from "./dataImportConfig";
import { MeasurementZod } from "./measurement";
import { PartitionZod } from "./partition";
import { PartitionerZod } from "./partitioner";

export const solutionPhases = [
  "New",
  "Development",
  "Production",
  "Archived",
] as const;
export const SolutionPhaseZod = z.enum(solutionPhases);
export type SolutionPhase = z.infer<typeof SolutionPhaseZod>;

export const SolutionZod = AuditZod.extend({
  solutionId: z.number(),
  name: z.string(),
  dataInterval: SamplingPeriodZod.optional(),
  timezone: z.string(),
  description: z.string(),
  phase: SolutionPhaseZod,
});

export type Solution = z.infer<typeof SolutionZod>;

export const SolutionExtraInfoZod = SolutionZod.extend({
  lastResultDate: DateTimeZod.nullable().optional(),
  lastImportDate: DateTimeZod.nullable().optional(),
  modelsCount: z.number(),
  scenariosCount: z.number(),
  influencingFactorsCount: z.number(),
  outliersCount: z.number(),
  hasBusinessHours: z.boolean(),
});
export type SolutionExtraInfo = z.infer<typeof SolutionExtraInfoZod>;

export const SolutionDraftZod = SolutionZod.omit({ solutionId: true });
export type SolutionDraft = z.infer<typeof SolutionDraftZod>;

export const SolutionDetailZod = SolutionZod.extend({
  aggregates: AggregateZod.array().default([]),
  measurements: MeasurementZod.array().default([]),
  partitioners: PartitionerZod.array().default([]),
  partitions: PartitionZod.array().default([]),
  charts: ChartZod.array().default([]),
  aggregatesAreDirty: z.boolean().default(false),
});

export type SolutionDetail = z.infer<typeof SolutionDetailZod>;

export const SolutionFirstStepsZod = z.object({
  hasData: z.boolean(),
  hasOutlier: z.boolean(),
  hasInfluencingFactor: z.boolean(),
  hasScenario: z.boolean(),
  hasModel: z.boolean(),
  hasProjectBusinessHours: z.boolean(),
  leafPartitionsWithBusinessHoursNum: z.number(),
  hasForecast: z.boolean(),
});
export type SolutionFirstSteps = z.infer<typeof SolutionFirstStepsZod>;

export const SolutionMetricsZod = z.object({
  leafPartitionsNum: z.number(),
  aggregatePartitionsNum: z.number(),
  measurementsNum: z.number(),
  outliersNum: z.number(),
  influencingFactorsNum: z.number(),
  firstDate: DateTimeZod.nullable().optional(),
  lastDate: DateTimeZod.nullable().optional(),
  hasProjectBusinessHours: z.boolean(),
  leafPartitionsWithBusinessHoursNum: z.number(),
});
export type SolutionMetrics = z.infer<typeof SolutionMetricsZod>;

export const SolutionUsedIdentifiersZod = SolutionZod.pick({
  solutionId: true,
  name: true,
});

export type SolutionUsedIdentifiers = z.infer<
  typeof SolutionUsedIdentifiersZod
>;
