import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import api, { EndpointOptions, getQueryString } from "../../../api";
import { FilterSetting } from "../../../models/primitives";
import {
  FactorEntityEntry,
  FactorEntityEntryZod,
  FactorListEntry,
  FactorListEntryZod,
  FactorUsedIdentifiers,
  FactorUsedIdentifiersZod,
  InfluencingFactor,
  InfluencingFactorDetail,
  InfluencingFactorDetailZod,
  InfluencingFactorPatch,
  InfluencingFactorRange,
  InfluencingFactorRangeZod,
  InfluencingFactorZod,
} from "../models/influencingFactor";
import { PaginatedResponse, paginatedResponse } from "../models/response";
import { getInfluencingFactorPath } from "../routes/influencingFactors";
import { getOutlierDetailPath } from "../routes/outliers";

export const INF_FACTORS_API = "/Prognos/InfluencingFactors";
const INF_FACTOR_RANGES_API = "/Prognos/InfluencingFactorRanges";

async function getInfluencingFactors(
  solutionId: string,
  options: EndpointOptions = {}
): Promise<PaginatedResponse<InfluencingFactor[]>> {
  const query = getQueryString(options);
  return paginatedResponse(InfluencingFactorZod.array()).parse(
    (
      await api.get(
        `/Prognos/Solutions/${solutionId}/InfluencingFactors?${query}`
      )
    ).data
  );
}

export function getFactorsQueryPrefix(solutionId: string) {
  return { queryKey: ["influencingFactors", solutionId] };
}

export const influencingFactorsQuery = (
  solutionId: string,
  options?: EndpointOptions
) => ({
  queryKey: [
    ...getFactorsQueryPrefix(solutionId).queryKey,
    "basicList",
    ...(options ? [options] : []),
  ],
  queryFn: () => getInfluencingFactors(solutionId, options),
});

async function getInfluencingFactorsList(
  solutionId: string,
  options: EndpointOptions = {}
): Promise<PaginatedResponse<FactorListEntry[]>> {
  const query = getQueryString(options);
  return paginatedResponse(FactorListEntryZod.array()).parse(
    (
      await api.get(
        `/Prognos/Solutions/${solutionId}/InfluencingFactors2?${query}`
      )
    ).data
  );
}

export const influencingFactorsListQuery = (
  solutionId: string,
  options?: EndpointOptions
) => ({
  queryKey: [
    ...getFactorsQueryPrefix(solutionId).queryKey,
    "moreInfoList",
    ...(options ? [options] : []),
  ],
  queryFn: () => getInfluencingFactorsList(solutionId, options),
});

type FactorEntities = {
  appliesToSolution: boolean;
  runConfigId?: number;
  modelId?: number;
  dataSegment?: {
    partitionId: number;
    measurementId: number;
  };
};

function getEntitiesString(entities: FactorEntities): string {
  const { appliesToSolution } = entities;
  let result = `AppliesToSolution=${appliesToSolution ? "True" : "False"}`;

  const { runConfigId } = entities;
  if (runConfigId) {
    result += `&RunConfigId=${runConfigId}`;
  }

  const { modelId } = entities;
  if (modelId) {
    result += `&ModelId=${modelId}`;
  }

  const { dataSegment } = entities;
  if (dataSegment) {
    result += `&measurementId=${dataSegment.measurementId}&partitionId=${dataSegment.partitionId}`;
  }

  return result;
}

async function getEntityFactorsList(
  solutionId: string,
  entities: FactorEntities,
  options: EndpointOptions = {}
): Promise<FactorEntityEntry[]> {
  const entitiesStr = getEntitiesString(entities);
  const queryStr = getQueryString(options);

  const { items } = paginatedResponse(FactorEntityEntryZod.array()).parse(
    (
      await api.get(
        `/Prognos/Solutions/${solutionId}/InfluencingFactorsByEntity?${entitiesStr}${
          entitiesStr && queryStr ? "&" + queryStr : ""
        }`
      )
    ).data
  );

  return items;
}

export const entityFactorsListQuery = (
  solutionId: string,
  entities: FactorEntities,
  options?: EndpointOptions
) => ({
  queryKey: [
    ...getFactorsQueryPrefix(solutionId).queryKey,
    "entityList",
    entities,
    ...(options ? [options] : []),
  ],
  queryFn: () => getEntityFactorsList(solutionId, entities, options),
});

async function getInfluencingFactor(
  influencingFactorId: string | number
): Promise<InfluencingFactorDetail> {
  return InfluencingFactorDetailZod.parse(
    (await api.get(`${INF_FACTORS_API}/${influencingFactorId}`)).data
  );
}

export const influencingFactorQuery = (
  influencingFactorId: string | number
) => ({
  queryKey: ["influencingFactor", influencingFactorId.toString()],
  queryFn: () => getInfluencingFactor(influencingFactorId),
});

async function createInfluencingFactor(
  solutionId: string,
  influencingFactor: InfluencingFactorDetail
): Promise<InfluencingFactorDetail> {
  return InfluencingFactorDetailZod.parse(
    (
      await api.post(
        `/Prognos/Solutions/${solutionId}/InfluencingFactors`,
        influencingFactor
      )
    ).data
  );
}

export const useCreateFactor = (solutionId: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (factor: InfluencingFactorDetail) =>
      createInfluencingFactor(solutionId, factor),
    onSuccess: (data) => {
      const id = data.influencingFactorId;
      const outlierLink = getOutlierDetailPath(solutionId, id);
      const factorLink = getInfluencingFactorPath(solutionId, id);

      toast.success(
        data.isOutlier
          ? t("Outlier saved successfully.")
          : t("Influencing factor saved successfully.")
      );
      queryClient.invalidateQueries(getFactorsQueryPrefix(solutionId));
      navigate(data.isOutlier ? outlierLink : factorLink);
    },
    onError: () => {
      toast.error(t("An error occurred while saving. Please try again."));
    },
  });
};

async function updateInfluencingFactor(
  influencingFactorId: string,
  patch: InfluencingFactorPatch
) {
  return InfluencingFactorDetailZod.parse(
    (await api.patch(`${INF_FACTORS_API}/${influencingFactorId}`, patch)).data
  );
}

export const useEditInfluencingFactor = (
  solutionId: string,
  retriesOnError = false
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: ({
      influencingFactorId,
      patch,
    }: {
      influencingFactorId: string;
      patch: InfluencingFactorPatch;
    }) => updateInfluencingFactor(influencingFactorId, patch),
    onSuccess: (newFactor, { influencingFactorId }) => {
      queryClient.setQueryData(
        ["influencingFactor", influencingFactorId],
        newFactor
      );
      queryClient.invalidateQueries(getFactorsQueryPrefix(solutionId));
    },
    onError: () => {
      toast.error(
        retriesOnError
          ? t(
              "The changes were not saved. Please wait or refresh the page to start over."
            )
          : t("The changes were not saved. Please try again.")
      );
    },
  });
};

async function copyInfluencingFactor(influencingFactorId: string | number) {
  return InfluencingFactorDetailZod.parse(
    (await api.post(`${INF_FACTORS_API}/${influencingFactorId}/Copy`)).data
  );
}

export const useCopyInfluencingFactor = (solutionId: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: copyInfluencingFactor,
    onSuccess: (factor) => {
      queryClient.invalidateQueries(getFactorsQueryPrefix(solutionId));
      navigate(
        getInfluencingFactorPath(solutionId, factor.influencingFactorId)
      );
    },
    onError: () => {
      toast.error(t("An error occurred while copying. Please try again."));
    },
  });
};

async function deleteInfluencingFactor(influencingFactorId: number | string) {
  return api.delete(`${INF_FACTORS_API}/${influencingFactorId}`);
}

export const useDeleteInfluencingFactor = (
  solutionId: string,
  redirect?: string
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (influencingFactorId: number | string) =>
      deleteInfluencingFactor(influencingFactorId),
    onSuccess: (_, influencingFactorId) => {
      toast.success(t("Influencing factor deleted successfully."));
      queryClient.invalidateQueries(getFactorsQueryPrefix(solutionId));
      queryClient.removeQueries(influencingFactorQuery(influencingFactorId));
      if (redirect) {
        navigate(redirect);
      }
    },
    onError: () => {
      toast.error(t("An error occurred while deleting. Please try again."));
    },
  });
};

async function deleteInfluencingFactorsByIds(ids: number[]) {
  return api.delete(`${INF_FACTORS_API}/Batch`, { data: { ids } });
}

export const useDeleteInfluencingFactorsByIds = (
  solutionId: string,
  isOutliers: boolean
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: deleteInfluencingFactorsByIds,
    onSuccess: (_, ids) => {
      toast.success(
        isOutliers
          ? t("Outliers deleted successfully.")
          : t("Influencing factors deleted successfully.")
      );
      queryClient.invalidateQueries(getFactorsQueryPrefix(solutionId));
      queryClient.removeQueries(...ids.map((id) => influencingFactorQuery(id)));
    },
    onError: () => {
      toast.error(t("An error occurred while deleting. Please try again."));
    },
  });
};

async function deleteInfluencingFactorsByFilter(
  solutionId: string | number,
  options: EndpointOptions
) {
  const query = getQueryString(options);
  return api.delete(
    `/Prognos/Solutions/${solutionId}/InfluencingFactors?${query}`
  );
}

export const useDeleteInfluencingFactorsByFilter = (
  solutionId: string,
  isOutliers: boolean
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const typeFilter: FilterSetting = [
    "isOutlier",
    "=",
    isOutliers ? "True" : "False",
  ];

  return useMutation({
    mutationFn: (options: EndpointOptions) => {
      if (
        !options.filter?.some(
          (e) =>
            e[0] === typeFilter[0] &&
            e[1] === typeFilter[1] &&
            e[2] === typeFilter[2]
        )
      ) {
        options = {
          ...options,
          filter: options.filter
            ? [...options.filter, typeFilter]
            : [typeFilter],
        };
      }
      return deleteInfluencingFactorsByFilter(solutionId, options);
    },
    onSuccess: () => {
      toast.success(
        isOutliers
          ? t("Outliers deleted successfully.")
          : t("Influencing factors deleted successfully.")
      );
      queryClient.invalidateQueries(getFactorsQueryPrefix(solutionId));
    },
    onError: () => {
      toast.error(t("An error occurred while deleting. Please try again."));
    },
  });
};

async function createInfluencingFactorRange(
  influencingFactorId: string,
  range: InfluencingFactorRange
): Promise<InfluencingFactorRange> {
  return InfluencingFactorRangeZod.parse(
    (await api.post(`${INF_FACTORS_API}/${influencingFactorId}/Ranges`, range))
      .data
  );
}

export const useCreateIFRange = (influencingFactorId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (range: InfluencingFactorRange) =>
      createInfluencingFactorRange(influencingFactorId, range),
    onSuccess: () => {
      queryClient.invalidateQueries(
        influencingFactorQuery(influencingFactorId)
      );
    },
    onError: () => {
      toast.error(t("An error occurred while saving. Please try again."));
    },
  });
};

async function updateInfluencingFactorRange(
  influencingFactorRangeId: number,
  patch: Partial<InfluencingFactorRange>
): Promise<InfluencingFactorRange> {
  return InfluencingFactorRangeZod.parse(
    (
      await api.patch(
        `${INF_FACTOR_RANGES_API}/${influencingFactorRangeId}`,
        patch
      )
    ).data
  );
}

type EditRangeParams = {
  influencingFactorRangeId: number;
  patch: Partial<InfluencingFactorRange>;
};

export const useEditIFRange = (influencingFactorId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: ({ influencingFactorRangeId, patch }: EditRangeParams) =>
      updateInfluencingFactorRange(influencingFactorRangeId, patch),
    onSuccess: (updatedRange, { influencingFactorRangeId }) => {
      queryClient.setQueryData<InfluencingFactorDetail>(
        ["influencingFactor", influencingFactorId],
        (factor) => {
          if (!factor) {
            return factor;
          }

          const rangeIndex = factor.ranges.findIndex(
            (r) => r.influencingFactorRangeId === influencingFactorRangeId
          );
          if (rangeIndex === -1) {
            return factor;
          }

          const newRanges = [...factor.ranges];
          newRanges.splice(rangeIndex, 1, updatedRange);

          return { ...factor, ranges: newRanges };
        }
      );
    },
    onError: () => {
      toast.error(
        t(
          "The changes were not saved. Please wait or refresh the page to start over."
        )
      );
    },
  });
};

async function deleteInfluencingFactorRange(
  influencingFactorRangeId: string | number
) {
  return api.delete(`${INF_FACTOR_RANGES_API}/${influencingFactorRangeId}`);
}

export const useDeleteIFRange = (influencingFactorId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (influencingFactorRangeId: number) =>
      deleteInfluencingFactorRange(influencingFactorRangeId),
    onSuccess: () => {
      queryClient.invalidateQueries(
        influencingFactorQuery(influencingFactorId)
      );
    },
    onError: () => {
      toast.error(t("An error occurred while deleting. Please try again."));
    },
  });
};

async function getFactorUsedIdentifiers(
  solutionId: string | number
): Promise<FactorUsedIdentifiers[]> {
  return FactorUsedIdentifiersZod.array().parse(
    (
      await api.get(
        `/Prognos/Solutions/${solutionId}/InfluencingFactors/UsedIdentifiers`
      )
    ).data
  );
}

export const factorUsedIdentifiersQuery = (solutionId: string) => ({
  queryKey: [...getFactorsQueryPrefix(solutionId).queryKey, "usedIdentifiers"],
  queryFn: () => getFactorUsedIdentifiers(solutionId),
});
