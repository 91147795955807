import React from "react";
import { AiOutlineClose } from "react-icons/ai";

import {
  FilterInstance,
  FilterInterface,
} from "../commonGrid/useFilteredGridRows";
import DebouncedInput from "./DebouncedInput";
import FilterDatePicker from "./FilterDatePicker";
import FilterRangePicker from "./FilterRangePicker";
import OperatorSelect, { Operator, getOperators } from "./OperatorSelect";
import Select from "./Select";

interface FilterComponentProps {
  id: string | number;
  filterInterface: FilterInterface;
  filters: FilterInstance[];
  onChange(filter: FilterInstance): void;
  refs: React.RefObject<HTMLInputElement>;
  cleanable?: boolean;
  maxWidth?: number;
}

export default function FilterComponent(
  props: FilterComponentProps
): JSX.Element {
  const {
    filters,
    onChange,
    refs,
    filterInterface,
    cleanable = true,
    maxWidth,
    ...rest
  } = props;
  const { id, type, options } = filterInterface;

  const operators = getOperators(type);
  const defaultOperator = operators[0] ?? "=";

  const filter: FilterInstance = filters.find((f) => f.id === id) ?? {
    ...filterInterface,
    parameter: "",
    operator: defaultOperator,
  };

  const handleFilterChange = (update: Partial<FilterInstance>) => {
    onChange({
      ...filter,
      ...update,
    });
  };

  const inputId = `${id}-input`;

  const handleOperatorChange = (operator: Operator) => {
    const update: Partial<FilterInstance> = { operator };
    if (
      operator === "☍" &&
      filter.operator !== "☍" &&
      filter.parameter !== ""
    ) {
      const parameterDate = new Date(filter.parameter);
      parameterDate.setDate(parameterDate.getDate() + 1);
      update.parameter = `${filter.parameter} - ${parameterDate.toISOString()}`;
    }
    if (operator !== "☍" && filter.operator === "☍") {
      update.parameter = filter.parameter.split(" - ")[0] ?? "";
    }
    if (operator === "∅" || operator === "*") {
      update.parameter = "";
    }
    handleFilterChange(update);
    setTimeout(() => document.getElementById(inputId)?.focus(), 200);
  };

  const getOperatorComponent = () => {
    switch (type) {
      case "string":
      case "number":
      case "date":
      case "datetime":
      case "user":
        return (
          <OperatorSelect
            options={operators}
            value={filter.operator}
            onChange={handleOperatorChange}
          />
        );
      default:
        return null;
    }
  };

  const getInputComponent = (operator: Operator): JSX.Element | null => {
    switch (type) {
      case "string":
      case "number":
      case "user":
        return (
          <DebouncedInput
            {...rest}
            id={inputId}
            type={type === "number" ? "number" : "text"}
            refs={refs}
            value={filter.parameter}
            disabled={operator === "∅" || operator === "*"}
            onChange={(e) => handleFilterChange({ parameter: e.target.value })}
          />
        );
      case "date":
      case "datetime":
        if (filter.operator === "☍") {
          return (
            <FilterRangePicker
              {...rest}
              value={filter.parameter}
              onChange={(parameter) => handleFilterChange({ parameter })}
            />
          );
        }
        return (
          <FilterDatePicker
            {...rest}
            value={filter.parameter}
            onChange={(parameter) => handleFilterChange({ parameter })}
          />
        );
      case "enum":
      case "boolean":
        return (
          <Select
            {...rest}
            options={options ?? []}
            value={filter.parameter}
            onChange={(parameter) => handleFilterChange({ parameter })}
          />
        );
    }
  };

  return (
    <div
      className="flex w-full items-center"
      style={{ maxWidth, paddingInline: 2 }}
    >
      {getOperatorComponent()}
      {getInputComponent(filter.operator)}
      {cleanable && filter.parameter !== "" && (
        <span
          className="cursor-pointer text-slate-500 ml-2 mr-1"
          onClick={() => handleFilterChange({ parameter: "" })}
        >
          <AiOutlineClose size={16} />
        </span>
      )}
    </div>
  );
}
