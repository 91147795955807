import React from "react";
import { Navigate, RouteObject } from "react-router";

import { z } from "zod";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getDataStorePath } from "./dataStore";

const Review = lazyWithRetry(() => import("../pages/Review/Review"));

const Partition = lazyWithRetry(() => import("../pages/Partition/Partition"));

const Chart = lazyWithRetry(() => import("../pages/Chart/Chart"));
const ChartTab = lazyWithRetry(() => import("../pages/Chart/tabs/ChartTab"));
const DatagridTab = lazyWithRetry(
  () => import("../pages/Chart/tabs/DatagridTab")
);
const StatisticsTab = lazyWithRetry(
  () => import("../pages/Chart/tabs/StatisticsTab")
);
const OutliersTab = lazyWithRetry(
  () => import("../pages/Chart/tabs/OutliersTab")
);

const REVIEW_PATH = "review";

export const ReviewTabZod = z.enum([
  "overview",
  "details",
  "heatmap",
  "charts",
]);
export type ReviewTab = z.infer<typeof ReviewTabZod>;

export const ChartTabZod = z.enum([
  "chart",
  "datagrid",
  "statistics",
  "outliers",
]);
export type ChartTab = z.infer<typeof ChartTabZod>;
const TabEnum = ChartTabZod.Enum;

export const reviewRoute: RouteObject = {
  path: REVIEW_PATH,
  children: [
    {
      index: true,
      element: <Navigate to={ReviewTabZod.Enum.overview} replace />,
    },
    { path: ":reviewTab", element: <Review /> },
    {
      path: `${ReviewTabZod.Enum.overview}/:partitionId`,
      element: <Partition />,
    },
    {
      path: `${ReviewTabZod.Enum.charts}/:chartId`,
      element: <Chart />,
      children: [
        { index: true, element: <Navigate to={TabEnum.chart} replace /> },
        { path: TabEnum.chart, element: <ChartTab /> },
        { path: TabEnum.datagrid, element: <DatagridTab /> },
        { path: TabEnum.statistics, element: <StatisticsTab /> },
        { path: TabEnum.outliers, element: <OutliersTab /> },
      ],
    },
  ],
};

export function getReviewPath(solutionId: string | number): string {
  return `${getDataStorePath(solutionId)}/${REVIEW_PATH}`;
}

export function getReviewPartitionPath(
  solutionId: string | number,
  partitionId: string | number,
  measurementId?: string | number | null
): string {
  return `${getReviewPath(
    solutionId
  )}/${ReviewTabZod.Enum.overview}?partitionId=${partitionId}${
    measurementId ? `&measurementId=${measurementId}` : ""
  }`;
}

export function getReviewDetailsPath(solutionId: string | number): string {
  return `${getReviewPath(solutionId)}/${ReviewTabZod.Enum.details}`;
}

export function getReviewHeatmapPath(solutionId: string | number): string {
  return `${getReviewPath(solutionId)}/${ReviewTabZod.Enum.heatmap}`;
}

export function getPartitionDetailPath(
  solutionId: string | number,
  partitionId: string | number,
  measurementId?: string | number | null
): string {
  return `${getReviewPath(solutionId)}/${ReviewTabZod.Enum.overview}/${partitionId}${
    measurementId ? `?measurementId=${measurementId}` : ""
  }`;
}

export function getChartsPath(solutionId: string | number): string {
  return `${getReviewPath(solutionId)}/${ReviewTabZod.Enum.charts}`;
}

export function getChartDetailPath(
  solutionId: string | number,
  chartId: string | number
): string {
  return `${getChartsPath(solutionId)}/${chartId}`;
}
