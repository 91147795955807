import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useRouteError } from "react-router";
import { Link } from "react-router";

import { ArrowLeftIcon } from "@heroicons/react/20/solid";

import { getImportPath } from "../../apps/DataStore/routes/import";
import { getChartsPath } from "../../apps/DataStore/routes/review";
import { getInfluencingFactorsPath } from "../../apps/PrognosAI/routes/influencingFactors";
import { getModelsPath } from "../../apps/PrognosAI/routes/models";
import { getOutliersPath } from "../../apps/PrognosAI/routes/outliers";
import { getResultsPath } from "../../apps/PrognosAI/routes/results";
import { getRunsPath } from "../../apps/PrognosAI/routes/runConfigs";
import ErrorCard from "../../components/ErrorCard";
import { getSolutionsPath } from "../../routes/solutions";
import { getTasksPath } from "../../routes/tasks";
import Forbidden from "../Forbidden/Forbidden";
import NotFound from "../NotFound/NotFound";

import errorImg from "../../apps/PrognosAI/images/illustrations/error.svg";
import lost from "../../apps/PrognosAI/images/illustrations/lost.svg";

type ExpectedErrorPayload = { response?: { status: number } };

type Entity =
  | "Solution"
  | "DataImportConfig"
  | "Chart"
  | "Outlier"
  | "InfluencingFactor"
  | "ImportConfig"
  | "Scenario"
  | "Model"
  | "Result"
  | "ResultSegment"
  | "Task";

interface ErrorPageProps {
  error?: unknown;
  entity?: Entity;
}

export default function ErrorPage(props: ErrorPageProps): JSX.Element {
  const { error = useRouteError(), entity } = props;
  const { t } = useTranslation();

  const typedError = error as ExpectedErrorPayload | null;

  if (typedError?.response?.status === 403) {
    return <Forbidden />;
  }
  if (typedError?.response?.status === 404) {
    return <NotFoundEntity entity={entity} />;
  }

  console.error(error);

  return (
    <section className="flex justify-center">
      <ErrorCard
        title={t("Oops!")}
        subtitle={t("something is wrong")}
        image={errorImg}
      >
        <p>{t("Sorry, an unexpected error has occurred.")}</p>
        <p>
          {t("Please check your internet connection and try again or return")}{" "}
          <Link to={getSolutionsPath()}>{t("Back to the main page")}</Link>.{" "}
        </p>
        <p>{t("If the problem persists, please contact the support.")}</p>
      </ErrorCard>
    </section>
  );
}

function NotFoundEntity(props: { entity: Entity | undefined }): JSX.Element {
  const { entity } = props;

  const { t } = useTranslation();

  const nfProps = useNotFoundProps(entity);

  if (!nfProps) {
    return <NotFound />;
  }

  const { subtitle, explanation, link } = nfProps;

  return (
    <section className="flex justify-center">
      <ErrorCard title="Error 404" subtitle={subtitle} image={lost}>
        <p>{explanation}</p>
        <p>
          <Link to={link?.href ?? getSolutionsPath()}>
            <ArrowLeftIcon className="w-4 h-4 mr-1" />
            {link?.label ?? t("Back to the main page")}
          </Link>
        </p>
      </ErrorCard>
    </section>
  );
}

function useNotFoundProps(entity: Entity | undefined): {
  subtitle: string;
  explanation: string;
  link: { href: string; label: string } | undefined;
} | null {
  const { t } = useTranslation();

  const { solutionId } = useParams();

  if (!entity) {
    return null;
  }

  switch (entity) {
    case "Chart":
      return {
        subtitle: t("Chart not found"),
        explanation: t(
          "The chart you are looking for does not exist. It might have been deleted."
        ),
        link: solutionId
          ? {
              href: getChartsPath(solutionId),
              label: t("Return to all charts"),
            }
          : undefined,
      };
    case "ImportConfig":
    case "DataImportConfig":
      return {
        subtitle: t("Import configuration not found"),
        explanation: t(
          "The import configuration you are looking for does not exist. It might have been deleted."
        ),
        link: solutionId
          ? {
              href: getImportPath(solutionId),
              label: t("Return to all imports"),
            }
          : undefined,
      };
    case "InfluencingFactor":
      return {
        subtitle: t("Influencing factor not found"),
        explanation: t(
          "The influencing factor you are looking for does not exist. It might have been deleted."
        ),
        link: solutionId
          ? {
              href: getInfluencingFactorsPath(solutionId),
              label: t("Return to all influencing factors"),
            }
          : undefined,
      };
    case "Model":
      return {
        subtitle: t("Model not found"),
        explanation: t(
          "The model you are looking for does not exist. It might have been deleted."
        ),
        link: solutionId
          ? {
              href: getModelsPath(solutionId),
              label: t("Return to all models"),
            }
          : undefined,
      };
    case "Outlier":
      return {
        subtitle: t("Outliers not found"),
        explanation: t(
          "The outliers you are looking for does not exist. It might have been deleted."
        ),
        link: solutionId
          ? {
              href: getOutliersPath(solutionId),
              label: t("Return to all outliers"),
            }
          : undefined,
      };
    case "Result":
      return {
        subtitle: t("Result not found"),
        explanation: t(
          "The result you are looking for does not exist. It might have been deleted."
        ),
        link: solutionId
          ? {
              href: getResultsPath(solutionId),
              label: t("Return to all results"),
            }
          : undefined,
      };
    case "ResultSegment":
      return {
        subtitle: t("Planning area and observable result not found"),
        explanation: t(
          "The planning area and observable result you are looking for does not exist. It might have been deleted."
        ),
        link: solutionId
          ? {
              href: getResultsPath(solutionId),
              label: t("Return to all results"),
            }
          : undefined,
      };
    case "Scenario":
      return {
        subtitle: t("Scenario not found"),
        explanation: t(
          "The scenario you are looking for does not exist. It might have been deleted."
        ),
        link: solutionId
          ? {
              href: getRunsPath(solutionId),
              label: t("Return to all scenarios"),
            }
          : undefined,
      };
    case "Solution":
      return {
        subtitle: t("Project not found"),
        explanation: t(
          "The project you are looking for does not exist. It might have been deleted."
        ),
        link: {
          href: getSolutionsPath(),
          label: t("Return to all projects"),
        },
      };
    case "Task":
      return {
        subtitle: t("Task not found"),
        explanation: t(
          "The task you are looking for does not exist. It might have been deleted."
        ),
        link: {
          href: getTasksPath(solutionId, undefined),
          label: t("Return to all tasks"),
        },
      };
  }
}
